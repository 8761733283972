import { useContext } from "react";
import { LanguageContext } from "../providers/languageProvider";
import { languageOptions, dictionaryList } from '../containers/language';
export const useLanguageContext = () => {
  const languageContext = useContext(LanguageContext);
  return {
    state:languageContext[0],
    dispatch: languageContext[1]
  
  }
};