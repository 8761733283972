import React from "react";
const Alert = ({ type, text ,show,close}) => {
  const check=()=>{
    switch (type) {
      case "Error":
        if (text.length > 0) {
          if (show) {
            return (
              <div
              className="alert alert-danger  alert-dismissible fade show"
                role="alert"
              >
                {text}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            );
          }
        }
        break;
      case "success":
        if (text.length > 0) {
          setTimeout(close, 5000);
          if (show) {
            return (
              <div
              className="alert alert-success alert-dismissible fade show"
                role="alert"
              >
                {text}
                <button
                  type="button"
                  className="close"
                  data-dismiss="alert"
                  aria-label="Close"
                  onClick={close}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            );
          }
        }
        break;}
  }
  
    // code block
  return check();
};
export default Alert;
