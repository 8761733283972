import React, { useState,useEffect } from "react";
import logo from "../../assets/image/logo.svg";
import compareIcon from "../../assets/image/compare_with_number.svg";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
//import { HashLink as Link } from "react-router-hash-link";
import { useHistory } from "react-router-dom";
import {
  ScrollingProvider,
  useScrollSection,
  Section,
} from "react-scroll-section";
import HamburgerMenu from "react-hamburger-menu";
const Header = (props) => {
  const {state, dispatch } = useLanguageContext();
  const [showMenu,setShowMenu]=useState(false);
  const [navClassName, setNavClassName]=useState("");
  const homeSection = useScrollSection("home");
  const aboutSection = useScrollSection("about");
  const serviceSection = useScrollSection("services");
  const sellCarSection = useScrollSection("sell-your-car");
  const contactSection = useScrollSection("contact-us");
  const ourCarsSection = useScrollSection("our-cars");
  const onHandleshowMenu =()=>{
var flag=showMenu;
setShowMenu(!flag);
  }

  const handleScroll = () => {

    if (window.scrollY < 63 && navClassName!="" ) {
      setNavClassName('');
    }else{
      if (window.scrollY >= 64 && navClassName!="rado-top-nav" ) {
        setNavClassName("rado-top-nav");
      }
    }
  };
  let history = useHistory();
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  },[navClassName]);
  const t9n=state.dictionary.NAVBAR;
  const switchLanguage=(languageName)=>{
    dispatch({
      type:languageName
    });
    history.push(`/${languageName}/`);
  }

  return (
    <header>
       
      <nav
        className={`d-flex navbar menu-bar fixed-top navbar-expand-lg ${showMenu?"rado-top-nav":""} ${navClassName}`}
      >
        <div className="container">
          <div className="d-flex flex-column">
            <a className="navbar-brand" href="#">
              <img src={logo} className="d-inline-block align-top" />
             
            </a>
          </div>
          <div
            className="navbar-toggler ml-auto"
           
          >
            <HamburgerMenu
    isOpen={showMenu}
    menuClicked={onHandleshowMenu}
    width={14}
    height={11}
    strokeWidth={3}
    rotate={0}
    color='#980002'
    borderRadius={0}
    animationDuration={0.5}
/>
          </div>
          <div
            className={`collapse navbar-collapse p-2 ${showMenu?'show d-flex':''}`}
            id="navbarSupportedContent"
          >
            <ul className={`navbar-nav  ${showMenu?'mr-auto':'ml-auto'}`}>
              <li className={`nav-item ${homeSection.selected?'active':''}`}>
                <span
                  className="nav-link"
                  onClick={()=>{
                    setShowMenu(false);
                    homeSection.onClick();}}
                  selected={homeSection.selected}
                >
                  {t9n.HOME} <span className="sr-only">(current)</span>
                </span>
              </li>
              <li className={`nav-item ${aboutSection.selected?'active':''}`}>
                <a
                  className="nav-link"
                  onClick={()=>{
                    setShowMenu(false);aboutSection.onClick();}}
                  selected={aboutSection.selected}
                >
                  {t9n.ABOUTUS} 
                </a>
              </li>
              <li className={`nav-item ${ourCarsSection.selected?'active':''}`}>
                <a
                  className="nav-link"
                  onClick={()=>{
                    setShowMenu(false);ourCarsSection.onClick();}}
                  selected={ourCarsSection.selected}
                >
                   {t9n.CARS} 
                </a>
              </li>
              <li className={`nav-item ${serviceSection.selected?'active':''}`}>
                <a
                  className="nav-link"
                  onClick={()=>{
                    setShowMenu(false);serviceSection.onClick();}}
                  selected={serviceSection.selected}
                >
                 {t9n.OUR_SERVICES} 
                </a>
              </li>
               <li className={`nav-item ${sellCarSection.selected?'active':''}`}>
                <a
                  className="nav-link"
                  onClick={()=>{
                    setShowMenu(false);sellCarSection.onClick();}}
                  selected={sellCarSection.selected}
                >
                   {t9n.WE_BUY} 
                </a>
              </li>
              <li className={`nav-item ${contactSection.selected?'active':''}`}>
                <a
                  className="nav-link"
                  onClick={()=>{
                    setShowMenu(false);contactSection.onClick();}}
                  selected={contactSection.selected}
                >
                  {t9n.CONTACT_US} 
                </a>
              </li>
                    {/*<li className={`nav-item ${homeSection.selected?'active':''}`}>
                <a className="nav-link" href="#">
                  <img src={compareIcon} />
                </a>
              </li> */}
              {/* <li className="nav-item">
                <a className="nav-link" href="#">
                <svg version="1.1" id="Layer_1" x="0px" y="0px"
	 width="22.583px" height="22.198px" viewBox="6.458 0 22.583 22.198" enable-background="new 6.458 0 22.583 22.198">
<circle fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" cx="17.947" cy="11.015" r="10.695"/>
<path fill-rule="evenodd" clip-rule="evenodd" fill="#FFFFFF" d="M27.173,10.921c0.008-0.577-0.029-1.153-0.128-1.721
	c-0.155-0.876-0.438-1.726-0.843-2.519c-0.398-0.778-0.907-1.498-1.508-2.132C24.091,3.913,23.4,3.363,22.645,2.92
	c-0.768-0.453-1.599-0.795-2.464-1.013c-0.442-0.112-0.892-0.192-1.349-0.239c-0.232-0.024-0.464-0.032-0.695-0.047
	c-0.128-0.009-0.239-0.031-0.369-0.026c-0.162,0.006-0.323,0.018-0.484,0.024c-0.241,0.009-0.481,0.027-0.72,0.053
	c-0.896,0.101-1.776,0.329-2.607,0.678c-0.81,0.34-1.572,0.796-2.258,1.348c-0.679,0.548-1.281,1.191-1.781,1.907
	C9.415,6.328,9.024,7.123,8.751,7.961C8.469,8.824,8.327,9.73,8.313,10.639l-0.018,1.249l1.95,0.029l0.014-0.935l-1.095,0.017
	c0,0,0.277-3.707,1.809-5.491L12.138,6.6l0.48-0.364l-1.15-1.289c0,0,3.162-3,6.032-2.623l0.012,1.244l0.667,0.01l0.017-1.212
	l0.085-0.065c2.882-0.292,5.926,2.836,5.926,2.836l-1.198,1.254l0.461,0.379l1.184-1.057c1.478,1.828,1.66,5.543,1.66,5.543
	l-1.095-0.05l-0.014,0.935l1.95,0.029L27.173,10.921z"/>
<polygon fill-rule="evenodd" clip-rule="evenodd" fill="#930209" points="18.739,10.631 17.946,11.065 15.265,6.172 "/>
<polygon fill-rule="evenodd" clip-rule="evenodd" fill="#84030A" points="17.242,11.451 17.947,11.065 15.265,6.172 "/>
<polygon fill-rule="evenodd" clip-rule="evenodd" fill="#930209" points="18.733,11.015 18.4,10.674 20.504,8.618 "/>
<polygon fill-rule="evenodd" clip-rule="evenodd" fill="#84030A" points="18.105,10.371 18.401,10.674 20.504,8.618 "/>
<path fill-rule="evenodd" clip-rule="evenodd" fill="#B50004" d="M18.911,10.844c0.244,0.445,0.081,1.004-0.364,1.248
	c-0.447,0.244-1.004,0.081-1.249-0.364c-0.245-0.446-0.081-1.004,0.364-1.249C18.108,10.234,18.667,10.398,18.911,10.844"/>
<text transform="matrix(1 0 0 1 15.9083 18.2767)" fill="#FFFFFF" font-family="'MyriadPro-Regular'" font-size="6.0202">1</text>
</svg>
                </a>
              </li>*/}
            </ul>
          </div>
          <ul className="nav flex-column ml-2 mr-2">
               <li className={`nav-item nav-lang-item ${state.selectedlang== state.languageOptions.dutch?'selected-nav-lang-item':''}`} onClick={()=>switchLanguage(state.languageOptions.dutch)}>{state.languageOptions.dutch}</li>
              <li className={`nav-item nav-lang-item ${state.selectedlang== state.languageOptions.french?'selected-nav-lang-item':''}`} onClick={()=>switchLanguage(state.languageOptions.french)}>{state.languageOptions.french}</li>
              <li className={`nav-item nav-lang-item ${state.selectedlang== state.languageOptions.english?'selected-nav-lang-item':''}`} onClick={()=>switchLanguage(state.languageOptions.english)}>{state.languageOptions.english}</li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
