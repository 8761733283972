import React, { useState } from "react";

const SellCarInfo = ({ mark, model, price, kilometer, fuel, t9n,
  year, handleMarkChange, handleModelChange, handleYearChange, handleKilometerChange, handleFuelChange, handlePriceChange }) => {

  return (<div className="row">
    <div className="col-xl-4 mb-3">
      <div className="form-group">
        <label>{t9n.MARK} </label>
        <input
          type="text"
          className="form-control sell-car-input"
          value={mark}
          onChange={handleMarkChange}
        />
      </div>
    </div>
    <div className="col-xl-4 mb-3">
      <div className="form-group">
        <label>{t9n.MODEL}  </label>
        <input
          type="text"
          className="form-control sell-car-input"
          value={model}
          onChange={handleModelChange}
        />
      </div>
    </div>
    <div className="col-xl-4 mb-3">
      <div className="form-group">
        <label>{t9n.YEAR} </label>
        <input
          type="text"
          className="form-control sell-car-input"
          value={year}
          onChange={handleYearChange}
        />
      </div>
    </div>
    <div className="col-xl-4 mb-3">
      <div className="form-group">
        <label>{t9n.KILOMETERS} </label>
        <input
          type="text"
          className="form-control sell-car-input"
          value={kilometer}
          onChange={handleKilometerChange}
        />
      </div>
    </div>
    <div className="col-xl-4 mb-3">
      <div className="form-group">
        <label>{t9n.FUEL} </label>
        <input
          type="text"
          className="form-control sell-car-input"
          value={fuel}
          onChange={handleFuelChange}
        />
      </div>
    </div>

    <div className="col-xl-4 mb-3">
      <div className="form-group">
        <label>{t9n.PRICE} ({t9n.OPTIONAL}) </label>
        <input
          type="text"
          className="form-control sell-car-input"
          value={price}
          onChange={handlePriceChange}
        />
      </div>
    </div>
  </div>);
}
export default SellCarInfo;