import React from "react";
const WageDescription=({description,t9n}) =>{
    return(
        <div className="col-xl-12">
               <div className="wagen-title mb-2">{t9n}</div>
        <div className="feature-box">
            <p>{description}</p>
        </div>
        </div>
    )
}
export default WageDescription;