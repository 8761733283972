import React from "react";
import moneyimg from "../../assets/image/money.jpg";
import { Link } from "react-router-dom";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
const sellCar = () => {
  const {state} = useLanguageContext();
  const t9n=state.dictionary.SellYourCar; 
  return (
    <section id="sell-your-car">
      <div className="content-box">
        <div className="content-title-White">
          <h1>-  {t9n.WEBUY} -</h1>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-6 col-md-6 ">
                <div className="lf-sell-car">
                  <div className="lf-sell-car-inner">
                    <h2>{t9n.SELL_YOUR_CAR}</h2>
                    <h3 className="mt-5">{t9n.GOOD_PRICE}</h3>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className=" sell-your-car-card">
                  <div className="sell-your-car-text">
                    <img src={moneyimg} alt="" />
                    <h2>{t9n.WE_CAN_bUY_YOUR_CAR} </h2>
                    <p>
                      {t9n.WE_CAN_bUY_YOUR_CAR_TEXT}
                    </p>
                    <div className="d-flex justify-content-center">
                    <Link to={`/${state.selectedlang}/Wij-kopen`}>
                    <button className="btn btn-primary"> {t9n.SELL_YOUR_CAR}</button>
                   </Link>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default sellCar;