import React, { Component } from "react";
import Select from "react-select";
import Range from "./range.jsx";
import ArrowDown from "../../assets/image/arrow-down.png";
import { Collapse } from 'react-bootstrap';
class SearchCar extends Component {
  constructor(props) {
    super(props);
    var today = new Date();
    var maxYear = today.getFullYear();
    this.state = {
      showCriteria: false,
      selectedMark: this.props.marks[0] || { value: 0, label: "Mark" },
      selectedFuel: this.props.fuels[0] || { value: 0, label: "Brandstof" },
      selectedGear: this.props.gears[0] || { value: 0, label: "versnelling" },
      selectedEruo: this.props.euroNorms[0] || { value: 0, label: "Euro Norm" },
      seletedRangePrice: { min: 0, max: 40000 },
      txtMinPrice: 0,
      txtMaxPrice: 40000,
      selectedRangeKilometer: { min: 0, max: 400000 },
      txtMinKilometer: 0,
      txtMaxKilometer: 400000,
      selectedRangeYear: { min: 1990, max: maxYear },
      txtMinYear: 1990,
      txtMaxYear: maxYear,
    };
  }


  handleShowCriteria = (e) => {
    e.preventDefault();
    var show = window.innerWidth > 991 ? false : !this.state.showCriteria;
    this.setState({ showCriteria: show });
  }

  handleHideCriteria = (e) => {
    e.preventDefault();
    this.setState({ showCriteria: false });
  }

  handleMarkChange = (selectedMark) => {
    this.setState({ selectedMark }, () => {
      this.getSearchResult();
    });
  };
  handleFuelkChange = (selectedFuel) => {
    this.setState({ selectedFuel }, () => {
      this.getSearchResult();
    });
  };
  handleGearChange = (selectedGear) => {
    this.setState({ selectedGear }, () => {
      this.getSearchResult();
    });
  };
  handleEuroChange = (selectedEruo) => {
    this.setState({ selectedEruo }, () => {
      this.getSearchResult();
    });
  };
  handleMinKiloChange = (e) => {
    if (!isNaN(e.target.value)) {
      let txtMaxKilometer = this.state.txtMaxKilometer;
      let txtMinKilometer = this.state.txtMinKilometer;
      let value = e.target.value;
      txtMinKilometer =
        value < 0
          ? 0
          : value > txtMaxKilometer
            ? txtMaxKilometer
            : Number(value);
      if (txtMinKilometer % 10000 == 0) {
        this.setState(
          {
            txtMinKilometer: txtMinKilometer,
            selectedRangeKilometer: {
              min: txtMinKilometer,
              max: txtMaxKilometer,
            },
          },
          () => {
            this.getSearchResult();
          }
        );
      } else {
        this.setState({
          txtMinKilometer: txtMinKilometer,
        });
      }
    }
  };
  handleMaxKiloChange = (e) => {
    if (!isNaN(e.target.value)) {
      let txtMaxKilometer = this.state.txtMaxKilometer;
      let txtMinKilometer = this.state.txtMinKilometer;
      let value = e.target.value;
      txtMaxKilometer =
        value < 0
          ? 400000
          : value > 400000
            ? 400000
            : value < txtMinKilometer
              ? txtMinKilometer
              : Number(value);
      if (txtMaxKilometer % 10000 == 0) {
        this.setState(
          {
            txtMaxKilometer: txtMaxKilometer,
            selectedRangeKilometer: {
              min: txtMinKilometer,
              max: txtMaxKilometer,
            },
          },
          () => {
            this.getSearchResult();
          }
        );
      } else {
        this.setState({
          txtMaxKilometer: txtMaxKilometer,
        });
      }
    }
  };
  onChangeKilometer = () => {
    this.getSearchResult();
  };
  onUpdatekilometer = (kilometerRange) => {
    if (
      kilometerRange[0] != this.state.selectedRangeKilometer.min ||
      kilometerRange[1] != this.state.selectedRangeKilometer.max
    ) {
      let selectedRangeKilometer = {
        min: kilometerRange[0],
        max: kilometerRange[1],
      };
      this.setState({
        selectedRangeKilometer,
        txtMinKilometer: selectedRangeKilometer.min,
        txtMaxKilometer: selectedRangeKilometer.max,
      });
    }
  };

  handleMinPriceChange = (e) => {
    if (!isNaN(e.target.value)) {
      let txtMaxPrice = this.state.txtMaxPrice;
      let txtMinPrice = this.state.txtMinPrice;
      let value = e.target.value;
      txtMinPrice =
        value < 0 ? 0 : value > txtMaxPrice ? txtMaxPrice : Number(value);
      if (txtMinPrice % 1000 == 0) {
        this.setState(
          {
            txtMinPrice: txtMinPrice,
            seletedRangePrice: { min: txtMinPrice, max: txtMaxPrice },
          },
          () => {
            this.getSearchResult();
          }
        );
      } else {
        this.setState({
          txtMinPrice: txtMinPrice,
        });
      }
    }
  };

  handleMaxPriceChange = (e) => {
    if (!isNaN(e.target.value)) {
      let txtMaxPrice = this.state.txtMaxPrice;
      let txtMinPrice = this.state.txtMinPrice;
      let value = e.target.value;
      txtMaxPrice =
        value < 0
          ? 40000
          : value > 40000
            ? 40000
            : value < txtMinPrice
              ? txtMinPrice
              : Number(value);
      if (txtMaxPrice % 1000 == 0) {
        this.setState(
          {
            txtMaxPrice: txtMaxPrice,
            seletedRangePrice: { min: txtMinPrice, max: txtMaxPrice },
          },
          () => {
            this.getSearchResult();
          }
        );
      } else {
        this.setState({
          txtMaxPrice: txtMaxPrice,
        });
      }
    }
  };
  onUpdatePrice = (PriceRange) => {
    if (
      PriceRange[0] != this.state.seletedRangePrice.min ||
      PriceRange[1] != this.state.seletedRangePrice.max
    ) {
      let seletedRangePrice = {
        min: PriceRange[0],
        max: PriceRange[1],
      };
      this.setState({
        seletedRangePrice,
        txtMinPrice: seletedRangePrice.min,
        txtMaxPrice: seletedRangePrice.max,
      });
    }
  };
  onChangePrice = () => {
    this.getSearchResult();
  };

  handleMinYearChange = (e) => {
    if (!isNaN(e.target.value) && e.target.value.length <= 4) {
      let txtMinYear = this.state.txtMinYear;
      let txtMaxYear = this.state.txtMaxYear;
      if (e.target.value.length < 4) {
        txtMinYear = e.target.value;
        this.setState({ txtMinYear });
      } else {
        txtMinYear =
          e.target.value > txtMaxYear
            ? txtMaxYear.max
            : e.target.value > 1990
              ? e.target.value
              : 1990;
        this.setState(
          {
            txtMinYear: txtMinYear,
            selectedRangeYear: { min: txtMinYear, max: txtMaxYear },
          },
          () => {
            this.getSearchResult();
          }
        );
      }
    } else {
      if (e.target.value == "") {
        let selectedYear = this.state.selectedYear;
        selectedYear.min = 1990;
        this.setState({
          selectedYear: selectedYear,
          selectedRangeYear: selectedYear,
        });
      }
    }
  };
  handleMaxYearChange = (e) => {
    if (!isNaN(e.target.value) && e.target.value.length <= 4) {
      let txtMinYear = this.state.txtMinYear;
      let txtMaxYear = this.state.txtMaxYear;
      var today = new Date();
      var maxYear = today.getFullYear();
      if (e.target.value.length < 4) {
        txtMaxYear = e.target.value;
        this.setState({ txtMaxYear });
      } else {
        txtMaxYear =
          e.target.value < txtMinYear
            ? txtMinYear
            : e.target.value < maxYear
              ? e.target.value
              : maxYear;
        this.setState(
          {
            txtMaxYear: txtMaxYear,
            selectedRangeYear: { min: txtMinYear, max: txtMaxYear },
          },
          () => {
            this.getSearchResult();
          }
        );
      }
    }
  };
  onUpdateYear = (YearRange) => {
    if (
      YearRange[0] != this.state.selectedRangeYear.min ||
      YearRange[1] != this.state.selectedRangeYear.max
    ) {
      let selectedRangeYear = {
        min: YearRange[0],
        max: YearRange[1],
      };
      this.setState({
        selectedRangeYear: selectedRangeYear,
        txtMaxYear: selectedRangeYear.max,
        txtMinYear: selectedRangeYear.min,
      });
    }
  };
  onChangeYear = () => {
    this.getSearchResult();
  };
  getSearchResult = () => {
    const criteria = {
      mark: this.state.selectedMark,
      gear: this.state.selectedGear,
      euroNorm: this.state.selectedEruo,
      fuel: this.state.selectedFuel,
      yearRange: this.state.selectedRangeYear,

      kilometerRange: this.state.selectedRangeKilometer,
      PriceRange: this.state.seletedRangePrice,
    };
    this.props.getSelectCriteria(criteria);
  };
  onClickReset = () => {
    var today = new Date();
    var maxYear = today.getFullYear();
    this.setState({
      selectedMark: { value: 0, label: "Mark" },
      selectedFuel: { value: 0, label: "Mark" },
      selectedGear: { value: 0, label: "Mark" },
      selectedEruo: { value: 0, label: "Euro Norm" },
      seletedRangePrice: { min: 0, max: 40000 },
      txtMinPrice: 0,
      txtMaxPrice: 40000,
      selectedRangeKilometer: { min: 0, max: 400000 },
      txtMinKilometer: 0,
      txtMaxKilometer: 400000,
      selectedRangeYear: { min: 1990, max: maxYear },
      txtMinYear: 1990,
      txtMaxYear: maxYear,
    });
  };
  render() {
    const colourStyles = {
      option: (styles, { isFocused }) => {
        return {
          ...styles,
          backgroundColor: isFocused ? "white" : null,
          color: isFocused ? "black !important" : "white",
          cursor: isFocused ? "pointer" : null,
        };
      },
    };
    const {
      selectedMark,
      selectedFuel,
      selectedGear,
      selectedEruo,
    } = this.state;
    const { marks, fuels, gears, euroNorms } = this.props;
    return (
      <div className="car-filter-panel">
        <div className="criteria-title " data-bs-toggle="collapse" onClick={this.handleShowCriteria}>
          <h5 className="criteria-lable text-center">Selecteer criteria</h5>
        </div>
        <div className="criterias-lg d-xl-block d-lg-block d-none">
          {marks && <Select
            value={selectedMark}
            defaultValue={marks[0]}
            onChange={this.handleMarkChange}
            options={marks}
            placeholder={"Marks..."}
            className="select-rado-container mt-3"
            classNamePrefix="select-rado"
            styles={colourStyles}
          />}
          {marks && fuels && <Select
            value={selectedFuel}
            defaultValue={selectedFuel}
            onChange={this.handleFuelkChange}
            options={fuels}
            placeholder={"Brandstof..."}
            className="select-rado-container mt-3"
            classNamePrefix="select-rado"
            styles={colourStyles}
          />}
          {marks && gears && <Select
            value={selectedGear}
            defaultInputValue={gears[0]}
            onChange={this.handleGearChange}
            options={gears}
            className="select-rado-container mt-3"
            classNamePrefix="select-rado"
            styles={colourStyles}
          />}
          {marks && euroNorms && <Select
            value={selectedEruo}
            defaultValue={euroNorms[0]}
            onChange={this.handleEuroChange}
            options={euroNorms}
            className="select-rado-container mt-3"
            classNamePrefix="select-rado"
            styles={colourStyles}
          />}
          <form>
            <div className="form-row mt-3">
              <div className="col-xl-12">
                <h5 className="criteria-lable">{this.props.t9n.KILOMETERS}</h5>
              </div>
              <div className="col-xl-12">
                <Range
                  step={10000}
                  domain={[0, 400000]}
                  values={[
                    this.state.selectedRangeKilometer.min,
                    this.state.selectedRangeKilometer.max,
                  ]}
                  onChange={this.onChangeKilometer}
                  onUpdate={this.onUpdatekilometer}
                />
              </div>
              <div className="col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.txtMinKilometer}
                  onChange={this.handleMinKiloChange}
                  onBlur={this.getSearchResult}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.txtMaxKilometer}
                  onChange={this.handleMaxKiloChange}
                  onBlur={this.getSearchResult}
                />
              </div>
            </div>
            <div className="form-row mt-3">
              <div className="col-xl-12">
                <h5 className="criteria-lable">{this.props.t9n.PRICE}</h5>
              </div>
              <div className="col-xl-12">
                <Range
                  step={1000}
                  domain={[0, 30000]}
                  values={[
                    this.state.seletedRangePrice.min,
                    this.state.seletedRangePrice.max,
                  ]}
                  onChange={this.onChangePrice}
                  onUpdate={this.onUpdatePrice}
                />
              </div>
              <div className="col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.txtMinPrice}
                  onChange={this.handleMinPriceChange}
                  onBlur={this.getSearchResult}
                />
              </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.txtMaxPrice}
                  onChange={this.handleMaxPriceChange}
                  onBlur={this.getSearchResult}
                />
              </div>
            </div>
            <div className="form-row mt-3">
              <div className="col-xl-12">
                <h5 className="criteria-lable">{this.props.t9n.YEAR}</h5>
              </div>

              <div className="col-xl-12">
                <Range
                  step={1}
                  domain={[1990, 2021]}
                  values={[
                    this.state.selectedRangeYear.min,
                    this.state.selectedRangeYear.max,
                  ]}
                  onChange={this.onChangeYear}
                  onUpdate={this.onUpdateYear}
                />
              </div>
              <div className="col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.txtMinYear}
                  onChange={this.handleMinYearChange}
                  onBlur={this.getSearchResult}
                />
              </div>
              <div className="col-xl-6">
                <input
                  type="text"
                  className="form-control"
                  value={this.state.txtMaxYear}
                  onChange={this.handleMaxYearChange}
                  onBlur={this.getSearchResult}
                />
              </div>
            </div>
          </form>
          <div className="d-flex">
            <button
              className="btn btn-primary-md mt-3 ml-auto"
              onClick={this.onClickReset}
            >
              Reset
            </button>
          </div> </div>
        <Collapse className={"criterias-md d-xl-none d-lg-none"} in={this.state.showCriteria} timeout={3000}>
          <div>
            <Select
              value={selectedMark}
              defaultInputValue={marks[0]}
              isClearable
              isSearchable
              onChange={this.handleMarkChange}
              options={marks}
              className="select-rado-container mt-3"
              classNamePrefix="select-rado"
              styles={colourStyles}
            />
            <Select
              value={selectedFuel}
              defaultValue={fuels[0]}
              onChange={this.handleFuelkChange}
              options={fuels}
              placeholder={"Brandstof..."}
              className="select-rado-container mt-3"
              classNamePrefix="select-rado"
              styles={colourStyles}
            />
            <Select
              value={selectedGear}
              defaultValue={gears[0]}
              onChange={this.handleGearChange}
              options={gears}
              className="select-rado-container mt-3"
              classNamePrefix="select-rado"
              styles={colourStyles}
            />
            <Select
              value={selectedEruo}
              defaultValue={euroNorms[0]}
              onChange={this.handleEuroChange}
              options={euroNorms}
              className="select-rado-container mt-3"
              classNamePrefix="select-rado"
              styles={colourStyles}
            />
            <form>
              <div className="form-row mt-3">
                <div className="col-xl-12">
                  <h5 className="criteria-lable">{this.props.t9n.KILOMETERS}</h5>
                </div>
                <div className="col-xl-12">
                  <Range
                    step={10000}
                    domain={[0, 400000]}
                    values={[
                      this.state.selectedRangeKilometer.min,
                      this.state.selectedRangeKilometer.max,
                    ]}
                    onChange={this.onChangeKilometer}
                    onUpdate={this.onUpdatekilometer}
                  />
                </div>
                <div className="col-xl-6">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.txtMinKilometer}
                    onChange={this.handleMinKiloChange}
                    onBlur={this.getSearchResult}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.txtMaxKilometer}
                    onChange={this.handleMaxKiloChange}
                    onBlur={this.getSearchResult}
                  />
                </div>
              </div>
              <div className="form-row mt-3">
                <div className="col-xl-12">
                  <h5 className="criteria-lable">{this.props.t9n.PRICE}</h5>
                </div>
                <div className="col-xl-12">
                  <Range
                    step={1000}
                    domain={[0, 30000]}
                    values={[
                      this.state.seletedRangePrice.min,
                      this.state.seletedRangePrice.max,
                    ]}
                    onChange={this.onChangePrice}
                    onUpdate={this.onUpdatePrice}
                  />
                </div>
                <div className="col-xl-6">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.txtMinPrice}
                    onChange={this.handleMinPriceChange}
                    onBlur={this.getSearchResult}
                  />
                </div>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.txtMaxPrice}
                    onChange={this.handleMaxPriceChange}
                    onBlur={this.getSearchResult}
                  />
                </div>
              </div>
              <div className="form-row mt-3">
                <div className="col-xl-12">
                  <h5 className="criteria-lable">{this.props.t9n.YEAR}</h5>
                </div>

                <div className="col-xl-12">
                  <Range
                    step={1}
                    domain={[1990, 2021]}
                    values={[
                      this.state.selectedRangeYear.min,
                      this.state.selectedRangeYear.max,
                    ]}
                    onChange={this.onChangeYear}
                    onUpdate={this.onUpdateYear}
                  />
                </div>
                <div className="col-xl-6">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.txtMinYear}
                    onChange={this.handleMinYearChange}
                    onBlur={this.getSearchResult}
                  />
                </div>
                <div className="col-xl-6">
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.txtMaxYear}
                    onChange={this.handleMaxYearChange}
                    onBlur={this.getSearchResult}
                  />
                </div>
              </div>
            </form>
            <div className="d-flex">

              <button
                className="btn btn-primary-md mt-3 ml-auto"
                onClick={this.onClickReset}
              >
                Reset
              </button>
            </div>
            <div className="d-flex justify-content-center">
              <img src={ArrowDown} onClick={this.handleHideCriteria} /></div>
          </div></Collapse>
      </div>
    );
  }
}
export default SearchCar;
