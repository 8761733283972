import React, { useState,useEffect } from "react";
import logo from "../../assets/image/logo.svg";
import compareIcon from "../../assets/image/compare.svg";
import { HashLink as Link } from "react-router-hash-link";
import {NavLink,useHistory}from "react-router-dom";
import HamburgerMenu from "react-hamburger-menu";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
const Navbar = ({carToCompare}) => {
  const [showMenu, setShowMenu] = useState(false);
  const {state, dispatch } = useLanguageContext(); 
 
  const t9n=state.dictionary.NAVBAR;
  const onHandleshowMenu = () => {
    var flag = showMenu;
    setShowMenu(!flag);
  };
useEffect(() => {
  setShowMenu(false);
},[])
  return (
    <header>
      <nav className={`d-flex navbar menu-bar  navbar-expand-lg rado-top-nav`}>
        <div className="container">
          <div className="d-flex flex-column">
            <a className="navbar-brand" href="/">
              <img src={logo} className="d-inline-block align-top" />
            </a>
          </div>
          <div className="navbar-toggler">
            <HamburgerMenu
              isOpen={showMenu}

              menuClicked={onHandleshowMenu}
              width={14}
              height={11}
              strokeWidth={3}
              rotate={0}
              color="#980002"
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>
          <div
            className={`collapse navbar-collapse p-2 ${
              showMenu ? "show d-flex" : ""
            }`}
            id="navbarSupportedContent"
          >
            <ul className={`navbar-nav  ${showMenu ? "mr-auto" : "ml-auto"}`}>
              <li className="nav-item"onClick={ ()=>setShowMenu(false)}>
                <Link to={`/${state.selectedlang}`} activeClassName="active" >
              
                 
                  <span className="nav-link">
                  {t9n.HOME} <span className="sr-only">(current)</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item ">
                <Link to={`/${state.selectedlang}/#about`} activeClassName="active">
                  {" "}
                  <span className="nav-link">
                  {t9n.ABOUTUS} <span className="sr-only">(current)</span>
                  </span>
                </Link>
              </li>

              <li className="nav-item "onClick={ ()=>setShowMenu(false)}>
                <NavLink to={`/${state.selectedlang}/Gallery`}  activeClassName="active">
                  {" "}
                  <span className="nav-link">
                  {t9n.CARS}  <span className="sr-only">(current)</span>
                  </span>
                </NavLink>{" "}
              </li>
              <li className="nav-item "onClick={ ()=>setShowMenu(false)}>
                <Link to={`/${state.selectedlang}/#services`}>
                  {" "}
                  <span className="nav-link">
                  {t9n.OUR_SERVICES} <span className="sr-only">(current)</span>
                  </span>
                </Link>
              </li>
              <li className="nav-item "onClick={ ()=>setShowMenu(false)}>
                <NavLink to={`/${state.selectedlang}/Wij-kopen`} activeClassName="active">
                  {" "}
                  <span className="nav-link">
                  {t9n.WE_BUY}  <span className="sr-only">(current)</span>
                  </span>
                </NavLink>{" "}
              </li>
              
              <li className="nav-item">
              <Link to={`/${state.selectedlang}/Contact-us`}>
                <span className="nav-link">
                {t9n.CONTACT_US}  <span className="sr-only">(current)</span>
                </span>
              </Link>
              </li>
 <li className="nav-item "onClick={ ()=>setShowMenu(false)}>
              <Link to={`/${state.selectedlang}/Compare`} > 
                  <span className="nav-link ">
                  {t9n.COMPARE} <span className="sr-only">(current)</span>
                <div className="compare-header">
                <img src={compareIcon} className="compare-header-icon pb-2"/>
               <span className="list-badge">
                 <span className="nbm-cars-in-compare">{carToCompare}</span></span>
                </div>
              
                 </span></Link>
              </li> 
              
            </ul>  
            </div>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
