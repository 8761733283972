import React from "react";
const WagenDetails = ({car,t9n}) => {
  return (
    <ul className="list-group wagen-details">
      <li className="list-group-item ">
        <div className="row">
          <div className="col-xl-6 col-lg-8">{t9n.MARK}</div>
          <div className="col-xl-6 col-lg-4">{car.mark}</div>
        </div>
      </li>
      <li className="list-group-item ">
        <div className="row">
          <div className="col-xl-6 col-lg-8">{t9n.MODEL}</div>
          <div className="col-xl-6 col-lg-4">{car.model}</div>
        </div>
      </li>
      <li className="list-group-item ">
        <div className="row">
          <div className="col-xl-6 col-lg-8">{t9n.KILOMETERS}</div>
          <div className="col-xl-6 col-lg-4">{car.kilometer}</div>
        </div>
      </li>
      <li className="list-group-item ">
        <div className="row">
          <div className="col-xl-6 col-lg-8">{t9n.YEAR}</div>
          <div className="col-xl-6 col-lg-4">{car.year}</div>
        </div>
      </li>
      <li className="list-group-item ">
        <div className="row">
          <div className="col-xl-6 col-lg-8">{t9n.GEAR}</div>
          <div className="col-xl-6 col-lg-4">{car.gear}</div>
        </div>
      </li>
      <li className="list-group-item ">
        <div className="row">
          <div className="col-xl-6 col-lg-8">{t9n.EURO_NORM}</div>
          <div className="col-xl-6 col-lg-4">{car.euroNorm}</div>
        </div>
      </li>
      <li className="list-group-item ">
        <div className="row">
          <div className="col-xl-6 col-lg-8">{t9n.FUEL}</div>
          <div className="col-xl-6 col-lg-4">{car.fuel}</div>
        </div>
      </li>

      <li className="list-group-item ">
        <div className="row">
          <div className="col-xl-6 col-lg-8">{t9n.MOTOR}</div>
          <div className="col-xl-6 col-lg-4">{car.cylinderCapacity}</div>
        </div>
      </li>
      <li className="list-group-item ">
        <div className="row">
          <div className="col-xl-6 col-lg-8">{t9n.SEAT}</div>
          <div className="col-xl-6 col-lg-4">{car.seats}</div>
        </div>
      </li>
      <li className="list-group-item ">
        <div className="row">
          <div className="col-xl-6 col-lg-8">{t9n.COLOREXT}</div>
          <div className="col-xl-6 col-lg-4">{car.color}</div>
        </div>
      </li>
      <li className="list-group-item ">
        <div className="row">
          <div className="col-xl-6 col-lg-8">{t9n.COLORINT}</div>
          <div className="col-xl-6 col-lg-4">{car.innerColor}</div>
        </div>
      </li>
      <li className="list-group-item ">
        <div className="row">
          <div className="col-xl-6 col-lg-8">PK</div>
          <div className="col-xl-6 col-lg-4">{car.pk}</div>
        </div>
      </li>
    </ul>
  );
};
export default WagenDetails;
