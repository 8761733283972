import React from "react";
import Logo from "../../assets/image/logo.svg";
import FbLogo from "../../assets/image/footer/icon facebook footer-01.svg";
import InstaLogo from "../../assets/image/footer/icon insta footer-01.svg";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
const Footer = () => {
  const { state } = useLanguageContext();
  const t9n = state.dictionary.FOOTER;
  return (
    <footer>
      <div className="container-fluid">
        <div className="row foooter-content">
          <div className="col-lg-3 col-md-12">
            <img src={Logo} alt="Rado cars" />
            <p className="mt-3 text-justify pr-xl-5 mr-xl-5 pr-lg-3 mr-lg-3">
              {t9n.DESCRIPTION}
            </p>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="foooter-content-title">Contact us</div>
            <address className="footer-address-content">
              <b>Rado Cars BVBA</b>
              <br />
              Oudenaardsesteenweg 279
              <br />
              9420 Erpe Mere
              <br />
              België
              <br />


              <div className="d-flex flex-row">
                Gsm:
                <div className="d-flex flex-column footer-address-content-tel pl-1">
                  <a href="tel:+32486707057">+32 (0) 486 70 70 57</a>
                  <a href="tel:+32484276300">+32 (0) 484 27 63 00</a>
                </div>

              </div>
              Fax: <span className="pl-1"> +32 5 361 70 39</span>
              <br />
              <a href="/en">www.radocars.be</a>
              <br />
              <a href="mailto:info@radocars.be">info@radocars.be</a>
            </address>
          </div>
          <div className="col-lg-3 col-md-4 col-sm-12">
            <div className="foooter-content-title">{t9n.OPENINGHOURS}</div>

            <ul className="nav flex-column">
              <li className="nav-open-hour">{t9n.MandayToFriday}:09:00 - 18:00 u</li>
              <li className="nav-open-hour">{t9n.SATURDAY}:09:00 - 14:00 u</li>
              <li className="nav-open-hour">{t9n.SUNDAY}: {t9n.CLOSED} </li>
            </ul>
          </div>
          {/* <div className="col-lg-2 col-md-6">
            {/* <div className="foooter-content-title">Links</div>
            <ul className="nav flex-column">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Show all Cars
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">n
                  Sell your car
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Show all reviews
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#" aria-disabled="true">
                  Get in touch
                </a>
              </li>
            </ul>
            <li></li> 
          </div> */}
          <div className="col-lg-2 col-md-4 col-sm-12 offset-lg-1">
            <div className="foooter-content-title">Follow us</div>
            <ul className="nav flex-column">
              <li className="nav-item">
                <a className="nav-link" target="_blank" href="https://www.instagram.com/radocarsbvba/">
                  Instagram
                  <img src={InstaLogo} alt="" className="ml-2" />
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" target="_blank" href="https://www.facebook.com/Rado-cars-bvba-970133839817748/">
                  Facebook <img src={FbLogo} alt="" className="ml-2" />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row butoom-footer">
          <div className="col-xl-6 d-flex align-items-center">
            <div className="copyright">
              © 2020 Rado Cars bvba. All Rights Reserved.
            </div>
          </div>
          <div className="col-xl-6">
            {/* <ul className="nav">
              <li className="nav-item">
                <a className="nav-link" href="#">
                  HOME
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  OVER ONZ
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Our cars
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Sell your Car
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Contact Us
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#">
                  Link
                </a>
              </li>
            </ul> */}
          </div>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
