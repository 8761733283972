import React from "react";
import imageTemplate from "../../assets/image/rado-1.jpeg";
import calenderIcon from "../../assets/image/car-icons/calendar-sm.svg";
import gearIcon from "../../assets/image/car-icons/gear-sm.svg";
import KilometerIcon from "../../assets/image/car-icons/kilometer-sm.svg";
import fuelIcon from "../../assets/image/car-icons/fuel-sm.svg";
import euroIcon from "../../assets/image/car-icons/euro-sm.svg";
import seatIcon from "../../assets/image/car-icons/seat-sm.svg";
import compareIcon from "../../assets/image/compare.svg";
import { Link } from "react-router-dom";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
const CarList = (props) => {
  const addCarToCompare = (event, id) => {
    event.preventDefault();
    props.addCarToCompare(id, event);
  }
  const { state } = useLanguageContext();
  const t9n = state.dictionary.CARS;
  if (props.cars.length > 0) {
    return props.cars.map((car) => (
      <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 car-card" key={car.id}>
        <Link to={`/${state.selectedlang}/Wagen/${car.id}`}>
          <div className="card border-0 rounded-0 mt-1 mb-3">
            <div className="card-img-top gallery-car-image">
              <img
                className="car-image"
                src={car.imageUrls[0]}
              />
              {car.sold && props.t9n.SOLD && <div className="car-is-sold">{props.t9n.SOLD}</div>}
              <div onClick={(event) => addCarToCompare(event, car.id)} className="card-compare-icon ">
                <span className="mr-2">{t9n.Compare}</span>
                <img src={compareIcon} />
              </div>

            </div>

            <div className="card-body">
              <div className="d-flex justify-content-between">
                <h5 className="card-title text-wrap  pr-3">{car.title}</h5>
                <h6 className="car-price text-nowrap mr-1 mt-auto">
                  &euro;{car.price}
                </h6>
              </div>

              <div className="car-details">
                <ul>
                  <li className="car-detail">
                    <img className="mr-2" src={KilometerIcon} />
                    <span>{car.kilometer}</span>
                  </li>
                  <li className="car-detail">
                    <img className="mr-1" src={calenderIcon} />
                    <span>{car.year}</span>
                  </li>
                  <li className="car-detail">
                    <img className="mr-2" src={gearIcon} alt="" />
                    <span>{car.gear}</span>
                  </li>
                  <li className="car-detail">
                    <img className="mr-2" src={euroIcon} alt="" />
                    <span>{car.euroNorm}</span>
                  </li>
                  <li className="car-detail">
                    <img className="mr-2" src={fuelIcon} alt="" />
                    <span>{car.fuel}</span>
                  </li>
                  <li className="car-detail">
                    <img className="mr-2" src={seatIcon} alt="" />
                    <span>{car.seats}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Link>
      </div>
    ));
  }
  return <h5>Loading...</h5>;
};
export default CarList;
