import React ,{useEffect,useState}from "react";
import axios from 'axios'; 
import CompareTable from "./compareTable.jsx";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
const Compare = ({carList,removeCarById,lang}) => {
  const [cars, setCars] = useState([]);
  const [features,setFeatures]=useState([]);
  useEffect(()=>{
    if(state.selectedlang!==lang){
      dispatch({
        type:lang
      });
    }
    loadContent(carList);
      },[carList]
  );
const {state,dispatch} = useLanguageContext();
const t9n=state.dictionary.CARS; 
const loadContent=async (carList)=> {

  await getCarLitToCompare(carList);
}
const getCarLitToCompare=(ids)=>{

  if(ids.length>0){
    var params="?"
    for(var i=0;i<3;i++){
      params+=ids[i]!=undefined?"key"+(i+1)+"="+ids[i]:"";
      params+=i<2?"&&":"";
    }
    
    axios.get(process.env.REACT_APP_API_URL+"/wagens/Compare" + params,
    {headers: {"Accept-Language": lang}}
    )
    .then(function(response) {
      axios.get(process.env.REACT_APP_API_URL+"/Features").then(function(response) {
        var features =response.data;
        setFeatures(features);
      })
      if(response.data.length>0){
        for(var i=0;i<response.data.length;i++){
          response.data[i].imageUrls.sort((a, b) =>a.localeCompare(b));
        }
        var newElement=response.data;
         setCars(newElement);
      }
    });}
  }
  
  const removeCar=(id)=>{
    var newCars=cars.filter(element=>element.id!=id);
    setCars(newCars);
    removeCarById(id);
  }

  return (
    <div className="container-fluid">
      <div className="row">
      <CompareTable cars={cars}features={features}removeCar={removeCar} t9n={t9n}/>
      </div>
    </div>
  );
};
export default Compare;
