import React ,{useEffect,useState}from "react";
import AddCarIcon from"../../assets/image/AddCar.png";
import checkIcon from "../../assets/image/wagen/icon-check-sign.svg";
const CompareTable=({cars,features,removeCar,t9n})=>{
    const [rows,setRows]=useState([]);
   
    const carDetailsTitles={
    title: "",
    mark: t9n.MARK,
    model:t9n.MODEL,
    price: t9n.PRICE,
    kilometer: t9n.KILOMETERS,
    year: t9n.YEAR,
    gear: t9n.GEAR,
    euroNorm: t9n.EURO_NORM,
    fuel: t9n.FUEL,
    cylinderCapacity: t9n.MOTOR,
    pk: "PK",
    seats: t9n.SEAT,
    color: t9n.COLOREXT,
    innerColor: t9n.COLORINT,
    }
  
    useEffect(() => {
        drawTable();
      }, [cars,features]);
      
    const drawTable=()=>{
 
        var keyNames = Object.keys(carDetailsTitles);
      
        let rows=[];
        let images=[];
        images.push(<td className="compareImageCell pt-2 pb-2"style={{width:"25%"}}></td>);
        for(let i=0;i<3;i++){
           
        if(cars[i]!=undefined){
            images.push(<td className="compareImageCell pt-2 pb-2"style={{width:"25%"}}><img src={cars[i].imageUrls[0]}/></td>);
          }else{
            images.push(<td className="compareImageCell pt-2 pb-2 "style={{width:"25%"}}><img src={AddCarIcon}/></td>);
          }
        }

        rows.push(images);
         keyNames.forEach(element => {
          let cell=[]
          cell.push(<td className="compareCell  pt-2 pb-2"style={{width:"25%"}}>{carDetailsTitles[element]}</td>);
          for(let i=0;i<3;i++){
        if(cars[i]!=undefined){
          cell.push(<td className="compareCell  pt-2 pb-2"style={{width:"25%"}}>{cars[i][element]}</td>);
        }else{
          cell.push(<td className="compareCell  pt-2 pb-2"style={{width:"25%"}}></td>);
        }
          }
          rows.push(<tr>{cell}</tr>)
          
        });

        features.forEach(element =>{
            let cell=[]
            let found=false;
            cell.push(<td className="compareCell  pt-2 pb-2"style={{width:"25%"}}>{element.label}</td>);
            for(var y=0;y<3;y++){
          if(cars[y]!=undefined){
            if(cars[y].features.some(feature => feature.featureName== element.label)){
              found=true;
              cell.push(<td className="compareCell  pt-2 pb-2"style={{width:"25%"}}><img className="mr-2" src={checkIcon} alt="" />
              </td>);
            }else{
              cell.push(<td className="compareCell  pt-2 pb-2"style={{width:"25%"}}></td>);
            }
          
                
          }else{
            cell.push(<td className="compareCell  pt-2 pb-2 pt-2 pb-2"style={{width:"25%"}}></td>);
          }
            }
            if(found){
              rows.push(<tr>{cell}</tr>)
            }else{
              cell=[];
            }
            
        });
        let cell=[]
        
        cell.push(<td className="compareCell  pt-2 pb-2 pt-2 pb-2"style={{width:"25%"}}></td>);
        for(let i=0;i<3;i++){
         
          if(cars[i]!=undefined){
            cell.push(<td className="compareCell  pt-2 pb-2 pt-2 pb-2"style={{width:"25%"}}>
              <button key={i} className="btn btn-primary" onClick={(e) => remove(i)}>{t9n.Remove}</button>
            </td>);
          }else{
            cell.push(<td className="compareCell  pt-2 pb-2 pt-2 pb-2"style={{width:"25%"}}>
          </td>);
          }
          
        }
        rows.push(<tr>{cell}</tr>)
        setRows(rows); 
      }

      const remove=(index)=>{
        var removeId=cars[index].id;
       
        removeCar(removeId);
      }
return (
    <div className="col-12">
   <table className="mt-2 mb-3" style={{width:"100%"}}>
               <tbody>
                 {rows}
               </tbody>
             </table>
    </div>
 
)
}
export default CompareTable;