import React from "react";
import { Slider, Rail, Handles, Tracks, Ticks } from "react-compound-slider";
const Handle = ({ handle: { id, value, percent }, getHandleProps }) => {
  return (
    <div
      style={{
        left: `${percent}%`,
        position: "absolute",
        marginLeft: -10,
        marginTop: 0,
        zIndex: 2,
        width: 20,
        height: 20,
        border: 0,
        textAlign: "center",
        cursor: "pointer",
        borderRadius: "50%",
        backgroundColor: "#C4BAB0",
        border: "5px solid #980002 ",
        color: "red",
      }}
      {...getHandleProps(id)}
    ></div>
  );
};
const Track = ({ source, target, getTrackProps }) => {
  return (
    <div
      style={{
        position: "absolute",
        height: 10,
        zIndex: 1,
        marginTop: 5,
        backgroundColor: "#C4BAB0",
        borderRadius: 5,
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {
        ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
      }
    />
  );
};

const Range = (props) => {
  const sliderStyle = {
    // Give the slider some width
    position: "relative",
    width: "100%",
    height: 35,
    border: "0",
  };

  const railStyle = {
    position: "absolute",
    width: "100%",
    height: 10,
    marginTop: 5,
    borderRadius: 5,
    backgroundColor: "#fff",
    border: "1px solid #980002",
  };
  return (
    <Slider
      rootStyle={sliderStyle}
      domain={props.domain}
      step={props.step}
      mode={2}
      onChange={props.onChange}
      values={props.values /* three values = three handles */}
      onUpdate={props.onUpdate}
    >
      <div
        style={
          railStyle /* Add a rail as a child.  Later we'll make it interactive. */
        }
      />
      <Rail>
        {({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}
      </Rail>
      <Handles>
        {({ handles, getHandleProps }) => (
          <div className="slider-handles">
            {handles.map((handle) => (
              <Handle
                key={handle.id}
                handle={handle}
                getHandleProps={getHandleProps}
              />
            ))}
          </div>
        )}
      </Handles>
      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          <div className="slider-tracks">
            {tracks.map(({ id, source, target }) => (
              <Track
                key={id}
                source={source}
                target={target}
                getTrackProps={getTrackProps}
              />
            ))}
          </div>
        )}
      </Tracks>
    </Slider>
  );
};
export default Range;
