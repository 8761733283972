import React from "react";
import LoadingGif from "../assets/image/icons/loading.gif";
const Loading=()=>{
    return (
        <div className="container-fluid loading-component">
            <div className="d-flex justify-content-center loading-content">
            <div className="d-flex align-items-center">
                <img src={LoadingGif} alt=""/>
            </div>
            </div>
        </div>
        )
}
export default Loading;