import React from "react";
import WhatsappIcon from "../../assets/image/whatsapp-call.svg";
import Callus from "../../assets/image/24-7-call.svg";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
import {
  useScrollSection
} from "react-scroll-section";


const HomeCover = () => {
  const ourCarsSection = useScrollSection("our-cars");
  const {state} = useLanguageContext(); 
  const homecover=state.dictionary.HomeCover;
 
   
  return (
    <section id="home">
      <div className="bg-parallax" id="home-cover">
        <div id="home-content-box">
          <div id="home-content-box-inner" className="text-center">
       
            <div
              id="home-conten-heading"
              className="d-flex justify-content-center "
            >
               
             <div className="slogan text-left pl-2 pr-2">
                <h3>DRIVE YOUR HAPPINESS</h3>
                <br />
                <h4>
                  Great prices,
                  <br /> Great vehicles,
                  <br />
                  Great Services
                </h4>
              </div> 
            </div>
           
            <div id="home-btn" className="mt-5">
           <a
                className="btn btn-lg btn-general btn-red"
                title="ONZE WAGENS"
                onClick={ourCarsSection.onClick}
                selected={ourCarsSection.selected}
              >
                {homecover.OURCARS}
              </a>
            </div>
            <div className="container">
              <div className="d-flex justify-content-sm-start justify-content-center">
            <div className="call-us-via d-flex flex-sm-row flex-column ">
              <a
                className="whatsapp-call d-flex flex-row mr-3 mb-2"
                href="tel:+32486707057"
              >
                <div className="call-icon d-flex align-items-center">
                  <img src={Callus} alt="" />
                </div>
                <div className="call-ref d-flex align-items-center">
                  <p>
                  {homecover.CALL}
                    <br />
                    0486 70 70 57 
                  </p>
                </div>
              </a>
              <a className="whatsapp-call d-flex flex-row" href="https://api.whatsapp.com/send?phone=+32486707057&text=Beste,%20Ik%20heb%20een%20auto%20te%20koop%20...">
                <div className="call-icon d-flex align-items-center">
                  <img src={WhatsappIcon} alt="" />
                </div>
                <div className="call-ref d-flex align-items-center">
                  <p>Whatsapp</p>
                </div>
              </a>
            </div> </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeCover;
