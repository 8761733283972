import React from "react";
import video from "../../assets/video/my-video.mp4";
import about from "../../assets/image/about-us.png";
import videoImage from "../../assets/image/video-image.jpg";

import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
const AboutUs = () => {
  const {state} = useLanguageContext(); 
  const aboutUs=state.dictionary.AboutUs;
  return (
    <section id="about">
      <div className="content-box">
        <div className="content-title-black">
          <h1>- {aboutUs.TITLE} -</h1> </div>
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-6 col-lg-6 col-md-6 col-12 order-lg-1 order-2 mb-2">
                {/*<div className="embed-responsive embed-responsive-16by9">
                   <iframe
                    className="embed-responsive-item"
                    width="526"
                    height="315"
                    src={video}
                  ></iframe> 
                </div>*/}<img src={videoImage} style={{width:"100%"}}/>
                <p className="text-justify">
                  {aboutUs.P3}
                </p>
              </div>
              <div className="col-xl-4 col-lg-6 col-md-6 col-12 order-lg-2 order-1 d-flex flex-column mb-2">
                <div className="about-us-text-item order-1">
                  <p className="text-justify">
                    {aboutUs.P1}
                  </p>
                </div>
                <p className="text-justify mt-4 order-lg-1 order-3">
                  {aboutUs.P2}
                </p>
                <div className="about-us-img mt-auto order-lg-3 order-2">
                  <img src={about} />
                </div>
              </div>
            </div>
          </div>
       
      </div>
    </section>
  );
};

export default AboutUs;
