import React from "react";
import serviceImg from "../../assets/image/service.jpg";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
const service = () => {
  const {state} = useLanguageContext(); 
  const t9n=state.dictionary.SERVICES;
  return (
      <div className="content-box">
        <div className="content-title-White">
          <h1>- {t9n.OUR_SERVICES} -</h1></div>
          <div className="container">
            <div className="row   d-flex justify-content-center">
              <div className="col-xl-4 col-lg-6 order-lg-1 order-2">
                <div className=" our-service-card">
                  <div className="our-service-text">
                    {/* <h2>We can help</h2>
                    <h3>Help help </h3> */}
                    <p className="text-justify p-3">
                    {t9n.OUR_SERVICES_TEXT}
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-lg-6 order-lg-2 order-1">
                <img src={serviceImg} alt="" className="w-100" />
              </div>
            </div>
          </div>
        </div>
  );
};
export default service;
