import React, { useState, useEffect } from "react";

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ImageGallary(props) {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: ".slider-nav",
  };

  const settingsThumbs = {
    slidesToShow: props.urls.length >= 5?5: props.urls.length,
    slidesToScroll: 1,
    asNavFor: ".slider-for",
    dots: false,
    centerMode: true,
    arrows: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
  };
  const slidesData =props.urls;

  return (
    <div className="col-xl-12">
    <div className="feature-box slider-wrapper">
   
      <Slider
        {...settingsMain}
        asNavFor={nav2}
        ref={(slider) => setSlider1(slider)}
      >
        {slidesData.map((slide,index) => (
          <div className="slick-slide" key={index}>
             {props.sold && props.t9n.SOLD &&<div className="wagen_is-sold">{props.t9n.SOLD}</div>}
            <img
              className="slick-slide-image"
              src={slide}
            />
          </div>
        ))}
      </Slider>
      <div className="thumbnail-slider-wrap">
        <Slider
          {...settingsThumbs}
          asNavFor={nav1}
          ref={(slider) => setSlider2(slider)}
        >
          {slidesData.map((slide,index) => (
            <div className="slick-slide" key={index}>
              <img
                className="slick-slide-image"
                src={slide}
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
    </div>
  );
}

export default ImageGallary;
