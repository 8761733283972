import React, { useState, useEffect } from "react";
import axios from "axios";

import SearchCar from "./SearchCar.jsx";
import CarList from "./carList.jsx";
import "../../assets/css/style.css";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
import { Pagination } from "react-bootstrap";
const Gallery = (props) => {
  const [navClassName, setNavClassName] = useState("");
  const [marks, setMarks] = useState([]);
  const [fuels, setFuels] = useState([]);
  const [gears, setGears] = useState([]);
  const [euroNorms, setEuroNorms] = useState([]);
  const [allCars, setCars] = useState([]);
  const [resultCars, setResultCars] = useState([]);
  const [displayCars, setDisplayCars] = useState([]);
  const { state, dispatch } = useLanguageContext();
  const [selectedPage, setSelectedPage] = useState(0);
  useEffect(() => {
    window.scrollTo(0, 0)
    if (state.selectedlang !== props.lang) {
      dispatch({
        type: props.lang
      });
    }
    getCriteriaData();
  }, [])
  useEffect(() => {
    if (resultCars.length > 0) {
      pagination(1, 16);
    } else {
      setDisplayCars([]);
    }

  }, [resultCars]);

  const pagination = (page_number, page_size) => {
    let displayCars = resultCars.slice((page_number - 1) * page_size, page_number * page_size);
    setDisplayCars(displayCars);
  }

  const onSelectPage = (pageNumber) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
    pagination(pageNumber, 16);
    setSelectedPage(pageNumber);
  }
  const addCarToCompare = (id, event) => {
    event.preventDefault();
    props.addCareToCompare(id, event);
  }
  const getCriteriaData = () => {
    var self = this;
    axios
      .get(process.env.REACT_APP_API_URL + "/wagens", {
        headers: {
          "Accept-Language": props.lang
        }
      })
      .then(function (response) {
        // handle success
        let data = response.data;
        var cars = response.data.cars.map(car => { car.imageUrls.sort((a, b) => a.localeCompare(b)); return car; });

        setMarks([{ value: 0, label: state.dictionary.CARS.MARK || "Merk" }, ...data.marks]);
        setFuels([{ value: 0, label: state.dictionary.CARS.FUEL || "Brandstof" }, ...data.fuels]);
        setGears([{ value: 0, label: state.dictionary.CARS.GEAR || "Versnelling" }, ...data.gears]);
        setEuroNorms([{ value: 0, label: "Euro Norm" }, ...data.euroNorms]);
        setResultCars(data.cars);
        setCars(cars);

      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };
  const getSelectCriteria = (criteria) => {
    const mark = criteria.mark;
    const gear = criteria.gear;
    const euroNorm = criteria.euroNorm;
    const fuel = criteria.fuel;
    const yearRange = criteria.yearRange;

    const kilometerRange = criteria.kilometerRange;
    const PriceRange = criteria.PriceRange;

    let cars = allCars || [];
    if (mark.value > 0) {
      cars = cars.filter((c) => c.mark == mark.label);
    }

    if (fuel.value > 0) {
      cars = cars.filter((c) => c.fuel == fuel.label);
    }

    if (euroNorm.value > 0) {
      cars = cars.filter((c) => c.euroNorm == euroNorm.label);
    }

    if (fuel.value > 0) {
      cars = cars.filter((c) => c.fuel == fuel.label);
    }

    if (gear.value > 0) {
      cars = cars.filter((c) => c.gear == gear.label);
    }
    if (
      yearRange.min > 1990 &&
      yearRange.min.toString().length == 4 &&
      yearRange.max < 2022 &&
      yearRange.max.toString().length == 4
    ) {
      let cars = cars.filter(
        (c) => c.year >= yearRange.min && c.year <= yearRange.max
      );
    }
    if (kilometerRange.min >= 0 && kilometerRange.max <= 400000) {
      cars = cars.filter(
        (c) =>
          c.kilometer >= kilometerRange.min && c.kilometer <= kilometerRange.max
      );
    }
    if (PriceRange.min >= 0 && PriceRange.max <= 30000) {
      cars = cars.filter(
        (c) => c.price >= PriceRange.min && c.price <= PriceRange.max
      );
    }
    setResultCars(cars);
  };

  const displayPages = (numberOfCars) => {
    let numberOfPages = Math.ceil(numberOfCars / 16);
    let pages = [];
    if (numberOfPages > 1) {

      if (selectedPage > 1) {
        pages.push(<li className="page-item pr-1 pl-1" onClick={() => onSelectPage(selectedPage - 1)}><span className="page-link">&lt;</span></li>);
      }

      for (let i = 1; i <= numberOfPages; i++) {
        pages.push(<li className={`page-item pr-1 pl-1 ${selectedPage == i ? "active" : ""}`} onClick={() => onSelectPage(i)}><span className="page-link">{i}</span></li>);

      }
      if (selectedPage < numberOfPages) {
        pages.push(<li className="page-item pr-1 pl-1" onClick={() => onSelectPage(selectedPage + 1)}><span className="page-link">	&gt;</span></li>)

      }
      return pages;

    }
  }
  return (
    <div className="car-list-content pt-5 mt-5 mb-5">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-2 col-lg-3 col-12">
            <SearchCar
              t9n={state.dictionary.CARS}
              marks={marks}
              fuels={fuels}
              gears={gears}
              euroNorms={euroNorms}
              getSelectCriteria={getSelectCriteria}
            />
          </div>
          <div className="col-xl-10 col-lg-9 col-12">
            <div className="row">
              <CarList cars={displayCars} t9n={state.dictionary.CARS} addCarToCompare={addCarToCompare} />

            </div>
            <nav aria-label="Page navigation">
              <ul className="pagination justify-content-center">
                {displayPages(resultCars.length)}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
