import React from "react";
import checkIcon from "../../assets/image/wagen/icon-check-sign.svg";
const WagenFeature = ({ t9n, features }) => {
  return (
    <div className="col-xl-12">
      <div className="wagen-title mb-2">{t9n.SPECIFICATION}</div>
      <div className="feature-box">
        <ul className="feature-list row">
          {features.map((item) => (
            <li className="col-md-4 col-6 feature-list-item"> <img className="mr-2" src={checkIcon} alt="" />
              <span>{item.featureName}</span></li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WagenFeature;
