import React from "react";
const thumbsContainer = {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 16,
    padding: 20
  };
  const thumb = {
    position: "relative",
    display: "inline-flex",
    borderRadius: 2,
    border: "1px solid #eaeaea",
    marginBottom: 8,
    marginRight: 8,
    width: 200,
    height: 200,
    padding: 4,
    boxSizing: "border-box"
  };
  const thumbInner = {
    display: "flex",
    minWidth: 0,
    overflow: "hidden"
  };
  
  const img = {
    display: "block",
    width: "auto",
    height: "100%"
  };
  const thumbButton = {
    position: "absolute",
    top: 10,
    right: 10,
    background: "rgba(0,0,0,.8)",
    color: "#fff",
    border: 0,
    borderRadius: "50%",
    cursor: "pointer",
    padding:"5px",
    width: "30px",
    height:"30px",
  };
const PhotoBox = ({ file, imageName, handleDrag, handleDrop,removeImage }) => {
  return (
    <div
      draggable={true}
      id={imageName}
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={handleDrag}
      onDrop={handleDrop}
      style={thumb} >
          <div style={thumbInner}>
            <img src={file.preview} style={img} alt="" />
          </div>
          <button
        style={thumbButton}
        onClick={((event)=>removeImage(imageName))
        }
      >
        x
      </button>
    </div>
  );
};

export default PhotoBox;