import React, { useState, useEffect } from "react";
import HomeCover from "./homeCover.jsx";
import AboutUs from "./aboutUs.jsx";
import SellCar from "./sellCar.jsx";
import WhyUs from "./whyUs.jsx";
import Header from "./homeNav.jsx";
import Service from "./service.jsx";
import Contact from "./contact.jsx";
import logo from "../../assets/image/logo.svg";
import metLogo from "../../assets/image/logo.jpg";
import MetaTags from 'react-meta-tags';
import OurCars from "./our-cars.jsx";
import Footer from "../../components/Footer/index.jsx";
import BackToTop from "../../assets/image/backtotop.svg";
import "../../assets/css/style.css";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
import { ScrollingProvider, Section } from "react-scroll-section";
import axios from "axios";

const Home = ({ lang }) => {
  const [cars, setCars] = useState([]);

  const { state, dispatch } = useLanguageContext();
  const getCarsList = () => {
    console.log(lang);
    console.log(process.env.REACT_APP_API_URL);

    axios
      .get(process.env.REACT_APP_API_URL + "/wagens/Specail", {
        headers: {
          "Accept-Language": lang,
          "Accept": "application/json"
        }
      })
      .then(function (response) {
        console.log(response);
        setCars(response.data);
      }).catch(function (error) {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.error('Response error:', error.response.data);
          console.error('Response status:', error.response.status);
          console.error('Response headers:', error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('Request error:', error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          console.error('Axios error:', error.message);
        }
      });
  };
  useEffect(() => {

    if (state.selectedlang !== lang) {
      dispatch({
        type: lang
      });
    }
    getCarsList();
  }, []);



  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }


  return (
    <React.Fragment>
      <ScrollingProvider>

        <Header />

        <MetaTags>
          <meta property="og:site_name" content="Rado Cars bvba" />
          <meta property="og:description" content="Rado Cars biedt u een brede schaal aan tweedehands auto's in uitstekende staat.
       Elke auto wordt met uiterste selectiviteit gekozen, 
       na zorgvuldig onderzoek van alle aspecten."/>

          <meta property="og:image" itemprop="image" content={"https://www.radocars.be" + metLogo} />

          <meta property="og:type" content="website" />
          <meta property="og:image:type" content="image/jpg" />

          <meta property="og:image:width" content="300" />
          <meta property="og:image:height" content="300" />

          <meta property="og:url" content="https://www.radocars.be" />

          <meta name="theme-color" content="#000000" />

        </MetaTags>
        <div className="backToTop d-flex">
          <img className="w-75 ml-auto" src={BackToTop} alt="" onClick={scrollToTop} />
        </div>
        <Section id="home">
          <HomeCover />
        </Section>
        <Section id="about">
          <AboutUs />
          <WhyUs />
        </Section>
        <Section id="our-cars">
          <OurCars cars={cars} />
        </Section>
        <Section id="services">
          <Service />
        </Section>
        <Section id="sell-your-car">
          <SellCar />
        </Section>
        <Section id="contact-us">
          <Contact />
        </Section>
        <Footer />
      </ScrollingProvider>{" "}
    </React.Fragment>
  );
}


export default Home;
