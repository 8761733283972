import fr from '../laguages/fr.json';
import en from '../laguages/en.json';
import nl from '../laguages/nl.json';

export const dictionaryList = { en, fr, nl };

export const languageOptions = {
  english: 'EN',
  french: 'FR',
  dutch: 'NL'
};