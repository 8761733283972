import React, { useContext, createContext, useState,useReducer} from 'react';
import logo from './logo.svg';
import { languageOptions, dictionaryList } from '../src/containers/language';
import './assets/css/font/font.css';
import Home from './containers/Home/index.jsx';
import Gallery from './containers/Gallery/index.jsx';
import Wagen from './containers/Wagen/index.jsx';
import Compare from './containers/Compare/index.jsx';
import Navbar from './components/Header/index.jsx';
import Footer from './components/Footer/index.jsx';
import ContactUs from './containers/Contact-us/index.jsx';
import SellYourCar from './containers/SellYourCar/index.jsx';
import { LanguageProvider } from './providers/languageProvider';
import {LanguageReducer} from './reducers/languageReducers';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom";

function App() {
  const initialState = {
    languageOptions:languageOptions,
    dictionary:dictionaryList.nl,
     selectedlang:languageOptions.dutch
  };
  const state = useReducer(LanguageReducer, initialState);

  const DefaultRoutes = () => {
    
    const [carToCompare,setCarToCompare]=useState(0);
    const [carListToCompare,setCarListToCompare]=useState([]);
    const removeCarById=(id)=>{
      var carNumber=carToCompare-1;
      var newArray = carListToCompare.filter(function(value, index, arr){ 
        return value !=id;})
      setCarToCompare(carNumber);
      setCarListToCompare(newArray);
    }
    const addCareToCompare=(id,event)=>{
      event.preventDefault();
      var findId= carListToCompare.includes(id);
      if(findId){
        removeCarById(id);
      }else{

        
        if(carListToCompare.length<3){
          var carNumber=carToCompare+1;
          var newArray =[...carListToCompare, id];
          setCarListToCompare(newArray);
          setCarToCompare(carNumber);
        }
      }
    }
    return (
      <div>
        <Navbar carToCompare={carToCompare}/>
        <Switch>
        <Route exact path="/EN/Gallery"><Gallery addCareToCompare={addCareToCompare} lang={'EN'}/></Route>
        <Route exact path="/FR/Gallery"><Gallery addCareToCompare={addCareToCompare} lang={'FR'}/></Route>
        <Route exact path="/NL/Gallery"><Gallery addCareToCompare={addCareToCompare} lang={'NL'}/></Route>
        <Route exact path="/EN/Wagen/:carId"children={({ match }) => (<Wagen {...match.params} addCareToCompare={addCareToCompare}lang={'EN'}/>)}/>
        <Route exact path="/FR/Wagen/:carId"children={({ match }) => (<Wagen {...match.params} addCareToCompare={addCareToCompare} lang={'FR'}/>)}/>
        <Route exact path="/NL/Wagen/:carId"children={({ match }) => (<Wagen {...match.params} addCareToCompare={addCareToCompare} lang={'NL'}/>)}/>
        <Route exact path="/EN/Wij-kopen/"><SellYourCar lang={'EN'} /></Route>
        <Route exact path="/FR/Wij-kopen/"><SellYourCar lang={'FR'}/></Route>
        <Route exact path="/NL/Wij-kopen/"><SellYourCar lang={'NL'}/></Route>
        
        <Route exact path="/EN/Compare"><Compare removeCarById={removeCarById}carList={carListToCompare} lang={'EN'}/></Route>
        <Route exact path="/FR/Compare"><Compare removeCarById={removeCarById}carList={carListToCompare} lang={'FR'}/></Route>
        <Route exact path="/NL/Compare"><Compare removeCarById={removeCarById}carList={carListToCompare} lang={'NL'}/></Route>
        <Route exact path="/EN/Contact-us"><ContactUs lang={'EN'}/></Route>
        <Route exact path="/NL/Contact-us"><ContactUs lang={'NL'}/></Route>
        <Route exact path="/FR/Contact-us"><ContactUs lang={'FR'}/></Route>
        
        </Switch>
        <Footer />
      </div>)};
  return (
    
  <Router>
    <div>
    <LanguageProvider value={state}>
      <Switch>
      <Route exact path="/"><Home lang={'NL'}/></Route>
      <Route exact path="/NL/"><Home lang={'NL'}/></Route>
      <Route exact path="/FR/"><Home lang={'FR'}/></Route>
      <Route exact path="/EN/"><Home lang={'EN'}/></Route>
     
        <Route component={DefaultRoutes} />
        
      </Switch>
      </LanguageProvider>
    </div>
  </Router> 
  );
}


export default App;
