import React from "react";
import { Link } from "react-router-dom";

import imageTemplate from "../../assets/image/rado-1.jpeg";
import calenderIcon from "../../assets/image/icons/calendar.svg";
import gearIcon from "../../assets/image/icons/gear-white.svg";
import KilometerIcon from "../../assets/image/icons/kilometer-white.svg";
import fuelIcon from "../../assets/image/icons/fuel.svg";
import euroIcon from "../../assets/image/icons/euro-white.svg";
import Slider from "react-slick";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
const OurCars = (props) => {


  const { state } = useLanguageContext();
  const t9n = state.dictionary.CARS;
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  console.log(props.cars.length);
  const cars = props.cars.length > 0 && Array.isArray(props.cars) ? props.cars.map(car => { car.imageUrls.sort((a, b) => a.localeCompare(b)); return car; }) : [];
  return (

    <div className="content-box">
      <div className="content-title-black">
        <h1>- {t9n.OUR_CARS} -</h1></div>
      <div className="container">
        <div className="row">

          {cars.map((car, index) => (
            <div key={index} className="col-xl-3 col-lg-3 col-md-6 col-12 mb-5" kye={index}>
              <div className="card our-car-item border-0">
                <div className="car-img">
                  <img
                    className="card-img-top"
                    src={car.imageUrls[0]}
                  />
                  <div className="car-overlay-banner ">
                    <ul className="list-group car-overlay-content">
                      <li className="list-group-item d-flex align-items-center row">
                        <div className="col-6 d-flex justify-content-end">


                          <img src={KilometerIcon} alt="" /></div>
                        <div className="col-6 d-flex justify-content-start">
                          <span>{car.kilometer}</span>
                        </div>
                      </li>

                      <li className="list-group-item d-flex align-items-center row">

                        <div className="col-6 d-flex justify-content-end ">
                          <img src={gearIcon} alt="" />

                        </div>
                        <div className="col-6 d-flex justify-content-start ">
                          <span  >{car.gear}</span>

                        </div>


                      </li>
                      <li className="list-group-item d-flex align-items-center row">
                        <div className="col-6 d-flex justify-content-end">
                          <img src={euroIcon} alt="" /> </div>
                        <div className="col-6 d-flex justify-content-start ">
                          <span>{car.euroNorm}</span> </div>
                      </li>
                    </ul>

                    <div className="d-flex justify-content-center">
                      <Link to={`/${state.selectedlang}/Wagen/${car.id}`}><button className="btn btn-primary-sm">
                        Meer info
                      </button></Link>
                    </div>
                  </div>
                  <div className="special-car-list d-flex justify-content-around">
                    <div className="flex-row d-flex align-items-center">
                      <img className="mr-2" src={calenderIcon} alt="" />
                      <span>{car.year}</span>
                    </div>
                    <div className="flex-row d-flex align-items-center">
                      <img className="mr-2" src={fuelIcon} />
                      <span>{car.fuel}</span>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <h5 className="card-title">{car.title}</h5>
                  <div className="card-text d-flex">
                    <h6 className="our-car-price ml-auto pt-3 mr-2">
                      &euro;{car.price}
                    </h6>
                    <div className="traingle mt-auto"></div>
                  </div>
                </div>
              </div></div>
          ))}

        </div>
        <div className="row d-flex justify-content-center">
          <Link to={`/${state.selectedlang}/Gallery`}>
            <button className="btn btn-primary">{t9n.SHOW_ALL_CAR}</button>
          </Link>
        </div>
      </div>
    </div>

  );
}

export default OurCars;
