import React from "react";
import Telefone from "../../assets/image/wagen/icon-phone.svg";

import Email from "../../assets/image/wagen/icon-email.svg";
import Address from "../../assets/image/wagen/icon-location.svg";

import ClientMessage from '../../common/ClientMessage.jsx';
const ContactInfo = ({ t9n, carInfo }) => {


  return (
    <div className="col-xl-12">
      <div className="wagen-title mb-2">{t9n.CONTACT_INFO}</div>

      <div className="feature-box ">
        <div className="row">
          <div className="col-md-6 col-12">
            <div className="flex-column">
              <div className="row contact-item d-flex align-items-center">
                <div className="col-2">
                  <img src={Address} alt="" />
                </div>
                <div className="col-10">
                  <p>
                    <span>
                      <strong>Oudenaardsesteenweg 279,</strong>
                    </span>
                    <br />
                    <span>9420 Erpe Mere</span>
                  </p>
                </div>
              </div>

              <div className="row contact-item d-flex align-items-center">
                <div className="col-2 ">
                  <img src={Telefone} alt="" />
                </div>
                <div className="col-10">
                  <p>
                    <a href="tel:+3253617039">+32 5 361 70 39</a>
                  </p>
                </div>
              </div>
              <div className="row contact-item d-flex align-items-center">
                <div className="col-2">
                  <img src={Email} alt="" />
                </div>
                <div className="col-10">
                  <p>
                    <a href="mailto:info@radocars.be">info@radocars.be</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <ClientMessage t9n={t9n} carInfo={carInfo} />
          </div>

        </div>
      </div>
    </div>
  );
};
export default ContactInfo;
