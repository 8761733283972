import React, { useState, useEffect } from "react";
import ImageGallary from "../../common/imageGallary.jsx";
import WagenFeature from "./wageFeatures.jsx";
import MobileWagenDetails from "./mobileWagenDetails.jsx";
import WagenDetails from "./wagenDetails.jsx";
import WageDescription from "./wagenDescription.jsx";
import ContactInfo from "./contact-info.jsx";
import Loading from "../../common/Loading.jsx";
import "../../assets/css/style.css";
import axios from "axios";
import MetaTags from "react-meta-tags";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
const Wagen = ({ carId, addCareToCompare, lang }) => {
  const [car, setCar] = useState({});
  const [showShareItems, setShowShareItems] = useState(false);
  const { state, dispatch } = useLanguageContext();
  const t9n = state.dictionary;
  const handleShareItems = () => {
    var flag = !showShareItems;
    if (flag) {
      document.addEventListener("click", closeShareItems);
    }
    setShowShareItems(flag);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (state.selectedlang !== lang) {
      dispatch({
        type: lang
      });
    }
    getCarData(carId);
  }, [carId]);
  const closeShareItems = () => {
    if (showShareItems) {
      setShowShareItems(false);
      document.removeEventListener("click", closeShareItems);
    }
  };

  const onClickAddToCompare = (e) => {
    addCareToCompare(carId, e);
  };
  const getCarData = (id) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/wagens/" + id, {
        headers: {
          "Accept-Language": lang
        }
      })
      .then(function (response) {
        response.data.imageUrls.sort((a, b) => a.localeCompare(b));
        setCar(response.data);
      });
    // handle success
  };
  const downloadPdf = async () => {
    const response = await axios
      .get(process.env.REACT_APP_API_URL + "/DownloadPDF/" + carId, { headers: { "Accept-Language": lang }, responseType: 'blob' });

    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileUrl = URL.createObjectURL(file);
    // Create a temporary anchor tag with the file URL and download attribute
    const link = document.createElement('a');
    link.href = fileUrl;
    link.setAttribute('download', 'mydocument.pdf');
    document.body.appendChild(link);
    link.click();
  }

  if (Object.keys(car).length === 0 && car.constructor === Object) {
    return <Loading />;
  } else {
    return (
      <div className="container">
        <MetaTags>
          <meta name="description" content={car.title + " " + t9n.CARS.EURO_NORM + ":" + car.euroNorm + " " + t9n.CARS.KILOMETERS + ":" +
            car.kilometer + " " + t9n.CARS.YEAR + ":" + car.year + " " + t9n.CARS.PRICE + ":" + car.price} />
          <meta property="og:site_name" content="Rado Cars bvba" />
          <meta property="og:type" content="product" />
          <meta property="og:title" content={car.title} />
          <meta
            property="og:url"
            content={`https://radocars.be/${lang}/Wagen/${car.id}`}
          />
          <meta property="og:description" content={car.title + " " + t9n.CARS.EURO_NORM + ":" + car.euroNorm + " " + t9n.CARS.KILOMETERS + ":" +
            car.kilometer + " " + t9n.CARS.YEAR + ":" + car.year + " " + t9n.CARS.PRICE + ":" + car.price} />
          <meta
            property="og:image"
            content={car.imageUrls[0]}
          />
        </MetaTags>
        <div className="row pt-5">
          <div className="col-xl-9 col-lg-9">
            <div className="wagen-title">{car.title}</div>
            <div className="wagen-actions d-flex justify-content-between">
              <button className="btn btn-gray" onClick={onClickAddToCompare}>
                <span className="icon icon-compare mr-2"></span>{t9n.CARS.Compare}
              </button>

              <button className="btn btn-gray" onClick={downloadPdf}>
                <span className="icon icon-pdf mr-2"></span>PDF
              </button>
              <button
                className="btn btn-gray btn-share"
                onClick={handleShareItems}
              >
                <span className="icon icon-share mr-2"></span>Share
                <div
                  className={`dropdown-menu mt-2 w-100 ${showShareItems ? "show" : "hidden"
                    }`}
                  aria-labelledby="dropdownMenuButton"
                >
                  <a
                    className="dropdown-item"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://api.whatsapp.com/send?text=https://radocars.be/${lang}/Wagen/${car.id}`}
                  >
                    <span>
                      <span className="share-icon whatsapp"></span>
                      WhatsApp
                    </span>
                  </a>
                  <a
                    className="dropdown-item"
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https://radocars.be/${lang}/Wagen/${car.id}`}
                  >
                    <span>
                      <span className="share-icon facebook"></span>
                      Facebook
                    </span>

                  </a>
                </div>
              </button>
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 mt-auto">
            <div className="wagen-price d-flex justify-content-center">
              &euro;{car.price}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-9 col-lg-9 col-md-12">
            <div className="row">
              <ImageGallary urls={car.imageUrls} sold={car.sold} t9n={t9n.CARS} />
              <MobileWagenDetails car={car} t9n={t9n.CARS} />
              <WageDescription t9n={t9n.CARS.DESCRIPTION} description={car.description} />
              <WagenFeature features={car.features} t9n={t9n.CARS} />
              <ContactInfo t9n={t9n.CONTACTUS} carInfo={car.title} />
            </div>
          </div>
          <div className="col-xl-3 col-lg-3 d-none d-lg-block d-xl-block">
            <WagenDetails car={car} t9n={t9n.CARS} />
          </div>
        </div>

        <div className="row"></div>
      </div>
    );
  }
};
export default Wagen;
