import axios from "axios";
import React, { useState, useEffect } from "react";
import Alert from "../../common/Alert";
import PhotoUploadDropzone from "../../common/photoUploadDropzone.jsx";

import SellCarInfo from "./sellCarInfo.jsx";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
const SellYourCar = ({ lang }) => {
  const [images, setImages] = useState([]);
  const [showAlert, setAlert] = useState(false);
  const [alertText, setAlertText] = useState("");
  const [alertType, setAlertType] = useState("");
  const [name, setName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [email, setEmail] = useState("");
  const [postCode, setPostCode] = useState("");
  const [imageAlert, setImageAlert] = useState("");
  const [carInfoAlert, setCarInfoAlert] = useState("");
  const [clientInfoAlert, setClientInfoAlert] = useState("");
  const [removeImages, setRemoveImages] = useState(false);
  const [mark, setMark] = useState("");
  const [model, setMode] = useState("");
  const [year, setYear] = useState("");
  const [fuel, setFuel] = useState("");
  const [price, setprice] = useState("");
  const [euroNorm, setEuroNorm] = useState("");
  const [kilometer, setKilometer] = useState("");
  const [gear, setGear] = useState("");
  const { state, dispatch } = useLanguageContext();
  const t9n = state.dictionary.SellYourCar;
  const carT9n = state.dictionary.CARS
  useEffect(() => {
    window.scrollTo(0, 0);
    if (state.selectedlang !== lang) {
      dispatch({
        type: lang
      });
    }
  }, []);
  const handleMarkChange = (e) => {
    e.preventDefault();
    setMark(e.target.value);
  };
  const handleModelChange = (e) => {
    e.preventDefault();
    setMode(e.target.value);
  };
  const handleYearChange = (e) => {
    e.preventDefault();
    setYear(e.target.value);
  };
  const handleKilometerChange = (e) => {
    e.preventDefault();
    setKilometer(e.target.value);
  };
  {
  /*const handleGearChange = (e) => {
    e.preventDefault();
    setGear(e.target.value);
  };*/}
  const handleFuelChange = (e) => {
    e.preventDefault();
    setFuel(e.target.value);
  };
  {/*const handleEuroNormChange = (e) => {
    e.preventDefault();
    setEuroNorm(e.target.value);
  };*/ }
  const handlePriceChange = (e) => {
    e.preventDefault();
    setprice(e.target.value);
  };

  const getCarImages = (files) => {
    setImages(files);
    setImageAlert("");
  };

  const handleNameChange = (event) => {
    event.preventDefault();
    setName(event.target.value);
  };
  const handleTelephonChange = (event) => {
    event.preventDefault();
    setTelephone(event.target.value);
  };
  const handleEmailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  };
  const handlePostCodeChange = (event) => {
    event.preventDefault();
    setPostCode(event.target.value);
  };

  const isEmail = (email) => {
    let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return regex.test(String(email).toLowerCase());
  };
  const isNotEmpty = (value) => {
    if (value == null || typeof value == "undefined") return false;
    return value.length > 0;
  };
  const isNumber = (num) => {
    return num.length > 0 && !isNaN(num);
  };

  const fieldValidation = (value, validationFunction) => {
    if (value === null || value.length === 0) return false;

    let isFieldValid = validationFunction(value);

    return isFieldValid;
  };

  const isValid = () => {
    var valid = true;
    if (images.length < 6) {
      setImageAlert("");
      valid &= fieldValidation(mark, isNotEmpty);
      valid &= fieldValidation(model, isNotEmpty);
      valid &= fieldValidation(year, isNumber);
      valid &= fieldValidation(kilometer, isNumber);
      valid &= fieldValidation(fuel, isNotEmpty);
      if (price == null || price.length > 0) {
        valid &= fieldValidation(price, isNumber);
      }
      if (valid) {
        setCarInfoAlert("");
        valid &= fieldValidation(name, isNotEmpty);
        valid &= fieldValidation(email, isEmail);
        valid &= fieldValidation(telephone, isNumber);
        valid &= fieldValidation(postCode, isNumber);
        if (valid) {
          setClientInfoAlert("");
        } else {
          setClientInfoAlert("U heeft verkeerde gegevens ingevoerd");
        }

      } else {
        setCarInfoAlert("U hebt lege of verkeerde gegevens");
      }
    } else {
      valid = false;
      setImageAlert("U moet slechts vijf foto's invoegen");
    }

    return valid;
  };
  const clearAll = () => {
    window.scrollTo(0, 0);
    setImages([]);
    setAlert(false);
    setAlertText("");
    setRemoveImages(true);
    setName("");
    setTelephone("");
    setEmail("");
    setPostCode("");
    setImageAlert("");
    setCarInfoAlert("");
    setClientInfoAlert("");
    setMark("");
    setMode("");
    setYear("");
    setFuel("");
    setprice("");
    setKilometer("");
  };
  const onSendClick = () => {
    window.scrollTo(0, 0);
    if (isValid()) {
      const formData = new FormData();

      var files = images
        .sort((a, b) => a.order - b.order)
        .map(
          (file, index) =>
            new File(
              [file.image],
              `-${index > 8 ? index + 1 : "0" + (index + 1)}.jpg`,
            ),
        );
      if (price !== null && price.length === 0) {
        formData.append("Price", 0);
      } else {
        formData.append("Price", price);
      }
      for (var i = 0; i < files.length; i++) {
        formData.append("Images", files[i]);
      }


      formData.append("Model", model);
      formData.append("Mark", mark);
      formData.append("Fuel", fuel);

      formData.append("Kilometer", kilometer);
      formData.append("Year", year);
      formData.append("Name", name);
      formData.append("telephone", telephone);
      formData.append("Email", email);
      formData.append("PostCode", postCode);
      axios
        .post(process.env.REACT_APP_API_URL + "/SellYourCar", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Accept: "application/json",
          },
        })
        .then(function (response) {
          clearAll();
          setAlertType("success");
          setAlertText("Succesvol opgeslagen!");
          setAlert(true);
        })
        .catch(function (error) {
          clearAll();
          setAlertType("Error");
          setAlertText("Error:De auto is niet opgeslagen door een faut");
          setAlert(true);
          console.log(error);
        });
    } else {
      setAlertType("Error");
      setAlertText("U hebt verkeerde gegevens ingevoerd");
      setAlert(true);

    }
  };
  const closeAlert = () => {
    setAlert(false);
    setAlertText("");
    setAlertType("");
  };
  return (
    <div className="container">
      {showAlert && (
        <Alert
          show={showAlert}
          text={alertText}
          type={alertType}
          close={closeAlert}
        />
      )}
      <div className="row">
        <div className="col-xl-12 mt-5">
          <p>
            {t9n.DO_YOU_WANT_TO_SELL_YOUR_CAR}</p>
        </div>
      </div>
      <div className="row-content">
        <label className="w-100 sell-content-title">{t9n.PHOTOS}</label>
        <p className="w-100 content-Alert">{imageAlert}</p>
        <div className="row">
          <PhotoUploadDropzone
            removeImages={removeImages}
            imageUrls={[]}
            getCarImages={getCarImages}
            dropClassName={'dropzone'}
            dragText={t9n.PHOTO_TEXT}
          />
        </div>
      </div>
      <div className="row-content">
        <label className="w-100 sell-content-title">{t9n.CAR_INFO}</label>
        <p className="w-100 content-Alert">{carInfoAlert}</p>
        <SellCarInfo
          t9n={carT9n}
          mark={mark}
          model={model}
          price={price}
          kilometer={kilometer}
          gear={gear}
          fuel={fuel}
          year={year}
          handlePriceChange={handlePriceChange}
          handleMarkChange={handleMarkChange}
          handleModelChange={handleModelChange}
          handleYearChange={handleYearChange}
          handleKilometerChange={handleKilometerChange}
          handleFuelChange={handleFuelChange}
        />
      </div>

      <div className="row-content">
        <label className="w-100 sell-content-title">{t9n.CLIENT_INFO}</label>
        <p className="w-100 content-Alert">{clientInfoAlert}</p>
        <div className="row">
          <form className=" col-xl-12">
            <div className="form-group">
              <input
                type="text"
                className="form-control sell-car-input"
                placeholder="UW NAAM"
                value={name}
                onChange={handleNameChange}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control sell-car-input"
                placeholder="UW TELEFOON- OF GSM NR."
                value={telephone}
                onChange={handleTelephonChange}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control sell-car-input"
                placeholder="EMAIL"
                value={email}
                onChange={handleEmailChange}
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                className="form-control sell-car-input"
                placeholder="POST CODE"
                value={postCode}
                onChange={handlePostCodeChange}
              />
            </div>
          </form>
        </div>
      </div>
      <div className="row row mb-3">
        <div className="col-12 d-flex">
          <button className="btn btn-primary ml-auto mr-2" onClick={clearAll}>
            {t9n.CANCEL}
          </button>
          <button className="btn btn-success" onClick={onSendClick}>
            {t9n.SEND}
          </button>
        </div>
      </div>
    </div>
  );
};
export default SellYourCar;
