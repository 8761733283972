import React from "react";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
import ContactInfo from "../../common/ContactInfo.jsx";

const location = {
  address: "Oudenaardsesteenweg 279, 9420 Erpe-Mere",
  lat: 50.9308,
  lng: 3.96626,
}; // our location object from earlier

const Conatct = () => {
  const {state} = useLanguageContext();
  const t9n=state.dictionary.CONTACTUS; 
  return (
      <div className="content-box">
        <div className="content-title-black">
          <h1>- {t9n.CONTACT_US} -</h1>
          </div>
          
<div className="container">
<ContactInfo showButton={true} t9n={t9n} lang={state.selectedlang}/>
</div>
         
        </div>
  );
};
export default Conatct;
