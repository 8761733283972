import React, { useEffect } from "react";

import ContactInfo from '../../common/ContactInfo.jsx';
import ClientMessage from '../../common/ClientMessage.jsx';
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";
const ContactUs = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])
    const { state } = useLanguageContext();
    const t9n = state.dictionary.CONTACTUS;
    return (
        <div className="container">
            <div className="clientMessageBox">
                <h2>{t9n.DO_YOU_HAVE_QUESTION}</h2>
                <p>{t9n.SEND_YOUR_QUESTIONS}</p>
                <ClientMessage t9n={t9n} carInfo={'Gee Auto'} />
            </div>
            <div className="clientMessageBox mb-3">
                <h2>Contacteer ons</h2>

                <ContactInfo showButton={false} t9n={t9n} lang={state.selectedlang} />
            </div>


        </div>

    )
}
export default ContactUs
