import React from "react";
const MobileWagenDetails = (props) => {
  const car = props.car;
  return (
    <div className="col-xl-12 d-xl-none d-lg-none">
    <div className="wagen-title mb-2">Algemene informatie</div>
    <div className="feature-box">
      <ul className="feature-list row">
      <li className="list-group-item col-md-4 col-sm-6 col-12">
      <div className="row">
          <div className="col-8 wagen-detail-item">{props.t9n.MARK}</div>
          <div className="col-4">{car.mark}</div>
        </div>
      </li>
      <li className="list-group-item col-md-4 col-sm-6 col-12">
        <div className="row">
          <div className="col-8 wagen-detail-item">{props.t9n.MODEL}</div>
          <div className="col-4">{car.model}</div>
        </div>
      </li>
      <li className="list-group-item col-md-4 col-sm-6 col-12">
        <div className="row">
          <div className="col-8 wagen-detail-item">{props.t9n.KILOMETERS}</div>
          <div className="col-4">{car.kilometer}</div>
        </div>
      </li>
      <li className="list-group-item col-md-4 col-sm-6 col-12">
        <div className="row">
          <div className="col-8 wagen-detail-item">{props.t9n.YEAR}</div>
          <div className="col-4">{car.year}</div>
        </div>
      </li>
      <li className="list-group-item col-md-4 col-sm-6 col-12">
        <div className="row">
          <div className="col-8 wagen-detail-item">{props.t9n.GEAR}</div>
          <div className="col-4">{car.gear}</div>
        </div>
      </li>
      <li className="list-group-item col-md-4 col-sm-6 col-12">
        <div className="row">
          <div className="col-8 wagen-detail-item">{props.t9n.EURO_NORM}</div>
          <div className="col-4">{car.euroNorm}</div>
        </div>
      </li>
      <li className="list-group-item col-md-4 col-sm-6 col-12">
        <div className="row">
          <div className="col-8 wagen-detail-item">{props.t9n.FUEL}</div>
          <div className="col-4">{car.fuel}</div>
        </div>
      </li>

      <li className="list-group-item col-md-4 col-sm-6 col-12">
        <div className="row">
          <div className="col-8 wagen-detail-item">{props.t9n.MOTOR}</div>
          <div className="col-4">{car.cylinderCapacity}</div>
        </div>
      </li>
      <li className="list-group-item col-md-4 col-sm-6 col-12">
        <div className="row">
          <div className="col-8 wagen-detail-item">{props.t9n.SEAT}</div>
          <div className="col-4">{car.seats}</div>
        </div>
      </li>
      <li className="list-group-item col-md-4 col-sm-6 col-12">
        <div className="row">
          <div className="col-8 wagen-detail-item">{props.t9n.COLOREXT}</div>
          <div className="col-4">{car.color}</div>
        </div>
      </li>
      <li className="list-group-item col-md-4 col-sm-6 col-12">
        <div className="row">
          <div className="col-8 wagen-detail-item">{props.t9n.COLORINT}</div>
          <div className="col-4">{car.innerColor}</div>
        </div>
      </li>
      <li className="list-group-item col-md-4 col-sm-6 col-12">
        <div className="row">
          <div className="col-8 wagen-detail-item">PK</div>
          <div className="col-4">{car.pk}</div>
        </div>
      </li>
    </ul>   </div>
    </div>
  );
};
export default MobileWagenDetails;
