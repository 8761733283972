import { dictionaryList } from '../containers/language';
export const LanguageReducer = (state,action) => {
    switch (action.type) {
        case 'EN':
            return {
                ...state,
                dictionary:dictionaryList.en,
                selectedlang:"EN"

            };
            case 'FR':
            return {
                ...state,
                dictionary:dictionaryList.fr,
                selectedlang:"FR"
            };
            case 'NL':
            return {
                ...state,
                dictionary:dictionaryList.nl,
                selectedlang:"NL"
            };
    }
}