import React from "react";

import DIVERSITY from "../../assets/image/why-us/diversity-01-01.svg";
import AVAILABILITY from "../../assets/image/why-us/availability-01-01.svg";
import Outstanding from "../../assets/image/why-us/outstanding work-01-01.svg";
import ProfExperience from "../../assets/image/why-us/prof experience-01-01.svg";
import SOURCEOFKNOWLEDGE from "../../assets/image/why-us/source of knowledge-01-01.svg";
import HAPPY from "../../assets/image/why-us/happy clienrt-01-01.svg";
import { useLanguageContext } from "../../hooks/useLanguageContext.jsx";

const WhyUs = () => {
  const {state} = useLanguageContext(); 
  const t9n=state.dictionary.WhyWe;
  return (
    <section id="why-us">
      <div className="content-box">
        <div className="content-title-black">
          <h1>-{t9n.WHYWE}-</h1></div>
          <div className="why-us-bg">
            <div className="container ">
              <div className="row row-cols-xl-3 row-cols-lg-3 row-cols-md-2  row-cols-sm-1">
                <div className="col-12 mb-5 mt-5">
                  <div className="card h-100 border-0">
                    <div className="card-body border-0">
                      <div className="why-we-option-title">
                        <img src={ProfExperience} alt="" />
                      </div>
                      <h5 className="why-we-card-title pl-2">{t9n.PROF_EXP_TITLE}</h5>
                      <p className="card-text p-2">
                      {t9n.PROF_EXP_TEXT}
                      </p>
                    </div>
                  </div>
                </div>
                  <div className="col-12 mb-5 mt-5">
                  <div className="card h-100 border-0">
                    <div className="card-body border-0">
                      <div className="why-we-option-title">
                        <img src={HAPPY} alt="" />
                      </div>
                      <h5 className="why-we-card-title pl-2">{t9n.CUSTOMER_SATISFACTION_TITLE}</h5>
                    <p className="card-text p-2">
                    {t9n.CUSTOMER_SATISFACTION_TEXT}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-5 mt-5">
                  <div className="card h-100 border-0">
                    <div className="card-body border-0">
                      <div className="why-we-option-title">
                        <img src={Outstanding} alt="" />
                      </div>
                      <h5 className="why-we-card-title pl-2">{t9n.EXCELLENT_WORK_TITLE}</h5>
                      <p className="card-text p-2">
                         {t9n.EXCELLENT_WORK_TEXT}
                      </p>
                    </div>
                  </div>
                </div>
                 <div className="col-12 mb-5 mt-5">
                  <div className="card h-100 border-0">
                    <div className="card-body border-0">
                      <div className="why-we-option-title">
                        <img src={DIVERSITY} alt="" />
                      </div>
                      <h5 className="why-we-card-title pl-2">{t9n.DIVERSITY_TITLE}</h5>
                      <p className="card-text p-2">
                        {t9n.DIVERSITY_TEXT}                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-5 mt-5">
                  <div className="card h-100 border-0">
                    <div className="card-body border-0">
                      <div className="why-we-option-title">
                        <img src={SOURCEOFKNOWLEDGE} alt="" />
                      </div>
                      <h5 className="why-we-card-title pl-2">{t9n.SOURCE_OF_KNOWLEDGE_TITLE}</h5>
                      <p className="card-text p-2">
                      {t9n.SOURCE_OF_KNOWLEDGE_TEXT}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-5 mt-5">
                  <div className="card h-100 border-0">
                    <div className="card-body border-0">
                      <div className="why-we-option-title">
                        <img src={AVAILABILITY} alt="" />
                      </div>
                      <h5 className="why-we-card-title pl-2"> {t9n.AVAILABILITY_TITLE}</h5>
                      <p className="card-text p-2">
                     {t9n.AVAILABILITY_TEXT}
                      </p>
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
      </div>
    </section>
  );
};
export default WhyUs;
