import React, { useState } from "react";
import axios from 'axios';
const ClientMessage = (props) => {
  const [name, setName] = useState('');
  const [telefone, setTelefone] = useState('');
  const [carInfo, setCarInfo] = useState(props.carInfo);
  const [email, setEmail] = useState('');
  const [messageText, setMessageText] = useState('');
  const [sendingEmailAlert, setSendingEmailAlert] = useState('');
  const [alertClassName, setAlertClassName] = useState('alertFaildSending');
  const resetInput = () => {
    setName('');
    setTelefone('');
    setEmail('');
    setSendingEmailAlert('');
    setMessageText('');
    setAlertClassName('alertFaildSending');
  }
  const handleNameChange = (event) => {
    event.preventDefault();
    setName(event.target.value);
  }
  const handleTelefonChange = (event) => {
    event.preventDefault();
    setTelefone(event.target.value);
  }
  const handleEmailChange = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
  }
  const handleMessageChange = (event) => {
    event.preventDefault();
    setMessageText(event.target.value);
  }
  const isEmail = (email) => {
    let regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    return regex.test(String(email).toLowerCase());
  }
  const isNotEmpty = (value) => {
    if (value == null || typeof value == 'undefined') return false;
    return (value.length > 0);
  }
  const isNumber = (num) => {
    return (num.length > 0) && !isNaN(num);
  }

  const fieldValidation = (value, validationFunction) => {
    if (value == null || value.length == 0) return false;

    let isFieldValid = validationFunction(value);
    if (isFieldValid) {

    } else {
      setAlertClassName("alertFaildSending");
    }
    return isFieldValid;
  }

  const isValid = () => {
    var valid = true;

    valid &= fieldValidation(name, isNotEmpty);
    valid &= fieldValidation(email, isEmail);
    valid &= fieldValidation(telefone, isNumber);
    valid &= fieldValidation(messageText, isNotEmpty);

    return valid;
  }

  const onSendClick = () => {
    setSendingEmailAlert("");
    if (isValid()) {
      const params = {
        name: name,
        telefone: telefone,
        email: email,
        messageText: 'Auto:' + carInfo + ' \n' + messageText
      };

      axios
        .post(process.env.REACT_APP_API_URL + "/ContactUs", params)
        .then(function (response) {
          setSendingEmailAlert(props.t9n.AlertMessageSuccess);
          setAlertClassName("alertSuccedSending");
          setInterval(
            function () { resetInput() },
            10000
          );
        });
    } else {
      setSendingEmailAlert(props.t9n.AlertMessageFail);
    }
  }
  return (
    <div className="row">
      <div className="col-xl-12">
        <form className="contat-info-form">
          <div className="form-group">

            <input type="text" className="form-control" placeholder={props.t9n.NAME} value={name} onChange={handleNameChange} />
          </div>
          <div className="form-group">

            <input type="email" className="form-control" placeholder={props.t9n.YOUR_TELEPHONE} value={telefone} onChange={handleTelefonChange} />
          </div>
          <div className="form-group">
            <input type="email" className="form-control" placeholder="E-mail" value={email} onChange={handleEmailChange} />
          </div>

          <div className="form-group">
            <textarea
              name="your-message"
              className="form-control your-message"
              cols="40"
              rows="8"
              placeholder={props.t9n.MESSAGE}
              value={messageText} onChange={handleMessageChange}
            />
          </div>
        </form>
      </div>
      <div className="col-xl-12 d-flex">
        <button type="submit" className=" flex-fill btn btn-primary-md" onClick={onSendClick}>
          {props.t9n.SEND}
        </button>
      </div>
      {
        sendingEmailAlert && <div className={`col-12 pt-3 ${alertClassName}`}>
          <p>{sendingEmailAlert}</p>
        </div>
      }
    </div>

  )
}
export default ClientMessage