import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import PhotoBox from "./photoBox.jsx";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
  padding: 20,
};
const thumb = {
  position: "relative",
  display: "inline-flex",
  borderRadius: 2,
  border: "1px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  width: 200,
  height: 200,
  padding: 4,
  boxSizing: "border-box",
};
const thumbInner = {
  display: "flex",
  minWidth: 0,
  overflow: "hidden",
};

const img = {
  display: "block",
  width: "auto",
  height: "100%",
};

const PhotoUploadDropzone = ({ getCarImages,imageUrls,dragText,removeImages,dropClassName}) => {
  const [files, setFiles] = useState([]);
  const [dragId, setDragIndex] = useState();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: "image/*",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file, index) =>
          Object.assign(
            { image: file,
              preview: URL.createObjectURL(file),
              order: index + 1,
            
          }),
        ),
      );
    },
  });

  const handleDrag = (ev) => {
    setDragIndex(ev.currentTarget.id);
  };

  const handleDrop = (ev) => {
    const dragBox = files.find((file) => file.image.name === dragId);
    const dropBox = files.find((file) => file.image.name === ev.currentTarget.id);

    const dragBoxOrder = dragBox.order;
    const dropBoxOrder = dropBox.order;

    const newfiles = files.map((box, index) => {
      if (box.image.name === dragId) {
        box.order = dropBoxOrder;
      }
      if (box.image.name === ev.currentTarget.id) {
        box.order = dragBoxOrder;
      }
      return box;
    });

    setFiles(newfiles);
  };
  const removeImage = (name) => {
    let oldFiles = files;
    let newFiles = oldFiles.filter((el) => el.image.name !== name);
    setFiles(newFiles);
  };
  const thumbs = ()=>{

 
  if(files.length>0){
    return (

    files
    .sort((a, b) => a.order - b.order)
    .map((file, index) => (
      <PhotoBox
        file={file}
        key={file.image.name}
        imageName={file.image.name}
        handleDrag={handleDrag}
        handleDrop={handleDrop}
        removeImage={removeImage}
      />
    )))
  
  }
else{
  if(imageUrls.length>0){

    return (imageUrls.map(image=>(
      <div
      style={thumb} >
          <div style={thumbInner}>
            <img src={image} style={img} alt="" />
          </div>
    </div>
    )))
    
  }
} }

  useEffect(
    () => {
      if(removeImages){
        setFiles([]);
      }
    },
    [removeImages],
  );
  useEffect(
    () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
      getCarImages(files);
    },
    [files],
  );
  return (
    
    <section className="container">
      <div {...getRootProps({ className: dropClassName})}>
        <input {...getInputProps()} />
        <p>{dragText}</p>
      </div>
      <aside style={thumbsContainer}>{thumbs()}</aside>
    </section>
  );
};
export default PhotoUploadDropzone;
